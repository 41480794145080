import axios from "axios";
import { environment } from '../../environment'

export default class UserTableService {

    constructor() {}

    private static API_URL = environment.URL_API;

    static async getTables(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + `user/tables?user=${params.user}&tabletype=${params.tabletype}`)

        return result;
    }

    static async saveBudget(model: any) {
        let result = null;
        result = await axios.post(this.API_URL + `user/budget`, model);

        return result;
    }

    static async register(params: any) {
        let result = null;
        result = await axios.put(this.API_URL + "user/tables", params)

        return result;
    }

    static async getTemplate(params) {
        let result = null;
        result = await axios.get(this.API_URL + `user/tabletemplate?type=${params.type}`)

        return result;
    }

    static convertTable(tabledata: any) {
        if(tabledata?.tablerole) return;
        let usertables: any[] = [];

        tabledata.forEach((c: any) => {
            if (c.convert) {
                let listdata: any = []

                c.data.forEach((v: any) => {
                    let j: any = {}

                    v.map((e: any) => {
                        let o: any = {}
                        e.values.forEach((w: any) => {
                            o[`${e.name}_${w.name}`] = w.value
                        })

                        return o
                    }).forEach((p: any) => {
                        for (let f in p) {
                            j[f] = p[f]
                        }
                    })

                    v = [j]
                    listdata.push(j)
                })

                c.data = listdata
                usertables.push(c)
            }

        })

        return usertables;
    }

}
