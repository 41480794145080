
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserService from '@/shared/services/user.service';
import { useToast } from 'vue-toast-notification';
import Swal from 'sweetalert2';


export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            $toast: useToast(),
            forgotPassword: false,
            sendedCode: false,
            userRole: null,
            userSession: null,
            submitButton: true,
            loginForm: {
                email: null,
                password: null
            },
            forgotForm: {
                code: null,
                newpassword: null
            }
        }
    },
    validations() {
        return {
            loginForm: {
                email: { required },
                password: { required }
            },
            forgotForm: {
                code: { required },
                newpassword: { required }
            }
        }
    },
    created() {
        localStorage.clear();
        // this.getSession();
    },
    methods: {
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.userSession = session

                if (!this.userSession.hasPayments && !this.userSession.active) {
                    this.$router.push({
                        path: "/register/payment",
                        query: {
                            type: this.userSession.idrol,
                            userId: this.userSession.iduser,
                        }
                    })

                    this.$toast.success("Your information has been saved, make the payment to continue!", { position: 'top-right', duration: 10000 })
                }
            }
        },
        submit: async function () {
            let response = null;
            this.submitButton = false;

            if(!this.forgotPassword) {
                response = await UserService.login(this.loginForm)
            }
            else {
                let request = null

                if(!this.sendedCode) {
                    request = { email: this.loginForm.email, type: "code" }
                }
                else {
                    request = { email: this.loginForm.email, type: "update", code: this.forgotForm.code, newpassword: this.forgotForm.newpassword }
                }

                response = await UserService.forgotPassword(request);
            }

            let resdata = response.data

            if (resdata.length > 0 || !resdata.error) {
                if(!this.forgotPassword) {
                    localStorage.clear()

                    if(resdata.data.hasPayments || resdata.data.idrol == 4) {
                        localStorage.setItem("usersession", JSON.stringify(resdata.data))

                        this.$toast.success(resdata.message, { position: 'top-right' })

                        this.$router.push({
                            path: "/admin/panel"
                        })
                    }
                    else {
                        this.$router.push({
                            path: "/register/payment",
                            query: {
                                type: resdata.data.idrol,
                                userId: resdata.data.iduser
                            }
                        })
                        this.$toast.warning("Please, complete the payment!", { position: 'top-right', duration: 3000 })
                    }
                }
                else {

                    Swal.fire({
                        title: "Reset Password",
                        text: resdata.message,
                        icon: "success",
                        timer: 7000,
                        timerProgressBar: true
                    }).then((result) => {
                        if(!this.sendedCode) {
                            this.sendedCode = true;
                            this.forgotPassword = true;
                        }
                        else {
                            this.sendedCode = false;
                            this.forgotPassword = false;
                        }
                    })
                }

            }
            else {
                this.$toast.error(resdata.message, { position: 'top-right' })

                if(!this.forgotPassword) {
                    this.loginForm.email = null
                    this.loginForm.password = null
                }
            }

            this.submitButton = true;
        }
    }
})



