<template>
  <footer class="footer-realtors">
    <div class="container-fixed">
      <div class="row w-100">
        <div class="img-brand col-xs-12 col-sm-12 col-md-3">
          <img src="../assets/Images/logo02.png" alt="" />
        </div>
        <div class="contact col-xs-12 col-sm-12 col-md-3">
          <ul class="">
            <li class="tw-pb-3">
              <a href="#" class="fs-4 tw-font-semibold">Contact</a>
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a
                href="/contact/realtor"
                class="tw-font-normal tw-text-[#dfdede] tw-ml-5"
                >Related to Realtor</a
              >
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a href="/contact/mlo" class="tw-text-[#dfdede] tw-ml-5"
                >Related to MLO</a
              >
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a href="/contact/title-company" class="tw-text-[#dfdede] tw-ml-5"
                >Related to Title Company</a
              >
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a href="/contact/inspector" class="tw-text-[#dfdede] tw-ml-5"
                >Related to Inspectors</a
              >
            </li>
          </ul>
        </div>
        <div class="documents col-xs-12 col-sm-12 col-md-3">
          <ul class="">
            <li class="tw-mb-4 tw-pt-3 fs-5 tw-font-semibold">
              <routerLink to="/faqs">FAQs</routerLink>
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a
                href="https://www.realtortools.org/media/REALTORTOLLS-POLICY-ENGLISH.pdf"
                class="fs-5 tw-font-semibold"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a
                href="https://www.realtortools.org/media/TERMS-AND-USES.pdf"
                class="fs-5 tw-font-semibold"
                target="_blank"
                >Terms of use</a
              >
            </li>
            <li class="tw-mb-4 tw-pt-3">
              <a
                href="https://www.realtortools.org/media/COPYRIGHT-HOME.pdf"
                class="fs-5 tw-font-semibold"
                target="_blank"
                >Copyright</a
              >
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="hidden-mobile">
        <div class="footer-realtors__section5__copyright pb-5">
          <span
            >© 2023 SOURCE RealtorTools, PBC. All rights reserved. Terms of Use
            | Privacy Policy Patents</span
          >
        </div>
      </div>
      <div class="visible-mobile">
        <div class="footer-realtors__section5__copyright pb-5">
          <span
            >© 2023 SOURCE RealtorTools, PBC. <br />
            All rights reserved. <br />
            Terms of Use | Privacy Policy Patents</span
          >
        </div>
      </div>
    </div>
  </footer>
</template>