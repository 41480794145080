<template>
  <div class="container container-video">
    <video width="640" height="480" controls>
      <source src="https://www.realtortools.org/media/REALTOR-TOOLS.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<style lang="scss" scoped>
.container-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  video {
    width: 100%;
  }
}
</style>