

import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import { useToast } from 'vue-toast-notification';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Swal from 'sweetalert2';
import ResidentialView from './ResidentialView.vue';
import moment from 'moment';


export default defineComponent({
    data() {
        return {
            $toast: useToast(),
            listContacts: [],
            listRoles: [],
            selectedContact: null,
            params: {
                usertype: 0
            },
            configDatePicker: {
                date: null
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            mail: {
                subject: null,
                message: null
            }
        }
    },
    components: {
        Loading,
    },
    created() {
        this.init();

    },
    methods: {
        init: function () {
            this.loadRoles();
            this.getContactForms();
        },
        deleteContactForm: async function(id) {
            this.loaderConfig.isLoading = true;

            let params = { forms: [id] };

            const response = await UserService.deleteContactForm(params);
            let resdata = response.data

            if (!resdata.error) {
                Swal.fire({
                    title: "Message",
                    text: "Successfully removed!",
                    icon: "success",
                    timer: 3000,
                    timerProgressBar: false
                });

                this.getContactForms();
            }
            else {
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false;
        },
        sendMail: async function() {
            this.loaderConfig.isLoading = true
            let errors = false;
            for(let w in this.mail) {
                if(!this.mail[w]) {
                    Swal.fire({
                        title: "Message",
                        text: "Field " + w + " is required!",
                        icon: "error",
                        timer: 3000,
                        timerProgressBar: false
                    });

                    this.loaderConfig.isLoading = false
                    errors = true;

                    break;
                }
            }

            if(errors) return;

            let params: any = { ...this.mail, name: this.selectedContact.firstname + " " + this.selectedContact.lastname, to: this.selectedContact.email }

            const response = await UserService.sendUserMail(params);
            let resdata = response.data

            if (!resdata.error) {
                this.mail.subject = null;
                this.mail.message = null;
                Swal.fire({
                    title: "Message",
                    text: "Mail sended to " + this.selectedContact.email,
                    icon: "success",
                    timer: 3000,
                    timerProgressBar: false
                });
            }
            else {
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false
        },
        formatDate: (date: any) => {
            return date ? moment(date[0]).format("MM/DD/YYYY") + " to " + moment(date[1]).format("MM/DD/YYYY") : null;
        },
        loadRoles: async function () {
            this.loaderConfig.isLoading = true
            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list
                this.listRoles.forEach((role, index) => {
                    role.abb = role.name.toLowerCase().replaceAll(" ", "")

                    if (role.name == "MLO") {
                        role.name = "MORTGAGE LOAN ORIGINATOR"
                    }

                    role.name = role.name.toUpperCase()
                })

            }
            else {
                this.$toast.error("Error loading roles", { position: 'top-right' })
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false
        },
        getContactForms: async function () {
            this.loaderConfig.isLoading = true

            let params: any = { ...this.params }

            if (this.configDatePicker.date) {
                params.from = moment(this.configDatePicker.date[0]).format("YYYY-MM-DD");
                params.to = moment(this.configDatePicker.date[1]).format("YYYY-MM-DD");
            }

            const response = await UserService.getContactForms(params);
            let resdata = response.data

            if (!resdata.error) {
                this.listContacts = resdata.data.results.map(a => { return { ...a, createdOn: a.createDate ? moment(a.createDate).format("MM/DD/YYYY") : null } })
            }
            else {
                this.$toast.error("Error loading roles", { position: 'top-right' })
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false
        }
    }
})


