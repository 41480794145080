
import { defineComponent } from "vue";
import axios from "axios";
import { environment } from "@/environment";

export default defineComponent({
  props: {
    user_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      notifications: false,
      notificationsList: null,
    };
  },
  computed: {
    newMessages() {
      return this.notificationsList !== undefined &&
        this.notificationsList !== null
        ? this.notificationsList.filter((message) => {
            return message.message_read === 0;
          }).length
        : 0;
    },
  },
  async created() {
    await this.getNotifications();
  },
  methods: {
    toggleNotifications() {
      this.notifications = !this.notifications;
    },
    async getNotifications() {
      const response = await axios(
        `${environment.URL_API}notifications?id_user=${this.user_id}`
      );
      this.notificationsList = response.data.notifications;
    },
    async markAsRead(id) {
      const response = await axios(
        `${environment.URL_API}notifications/mark-as-read-notification?id_read=${id}`
      );

      if (response.data.success) {
        this.getNotifications()
      }
    }
  },
});
