
import { defineComponent, toRaw } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import UserService from "@/shared/services/user.service";
import { useToast } from "vue-toast-notification";
import { environment } from "../../environment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import Swal from "sweetalert2";

export default defineComponent({
  data() {
    return {
      v$: useVuelidate(),
      $toast: useToast(),
      userSession: null,
      typeUser: 1,
      listRoles: [],
      countryList: [],
      stateList: [],
      submitButton: true,
      loaderConfig: {
        isLoading: true,
        fullPage: true,
      },
      userForm: {
        idrol: null,
        username: null,
        lastname: null,
        phonenumber: null,
        email: null,
        state: null,
        country: null,
        address: null,
        zipcode: null,
        password: null,
        companyname: null,
        licensenumber: null,
      },
      userInfo: null,
      countyList: [],
      paypal: null,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    // Renderizar el botón de PayPal después de que el componente se haya montado
  },
  validations() {
    return {
      userForm: {
        username: { required },
        lastname: { required },
        phonenumber: { required },
        email: { required },
        state: { required },
        country: { required },
        address: { required },
        zipcode: { required },
        password: { required },
        companyname: { required },
        licensenumber: { required },
      },
    };
  },
  created: function () {
    this.init();
    this.getSections();
  },
  methods: {
    init: async function () {
      // await this.loadRoles()
      // await this.loadCountries()
      localStorage.clear();

      if (this.$route.query.type && this.$route.query) {
        this.typeUser = parseInt(this.$route.query.type.toString());
        this.getUser(this.$route.query.userId);
      } else {
        this.$router.push({
          path: "/",
        });
      }
      this.loadUserInfo();
    },
    getUser: async function (user: any) {
      this.loaderConfig.isLoading = true;
      let response = (await UserService.getUser({ iduser: user })).data;

      if (!response.error) {
        if (response.data.hasPayments) {
          this.$router.push({
            path: "/loginusers",
          });

          this.$toast.success("You already made the payment!", {
            position: "top-right",
          });

          return;
        }

        this.userSession = {
          ...response.data,
          password: atob(response.data.password),
        };

        this.renderPayPalButton();
      } else {
        this.$toast.error("Error", { position: "top-right" });
        // console.log(response);
      }
      this.loaderConfig.isLoading = false;
    },
    renderPayPalButton() {
      let script = document.createElement("script");
      script.src = environment.PAYPAL_CDN_PROD;
      script.setAttribute("data-sdk-integration-source", "button-factory");
      document.body.appendChild(script);

      let code = "";

      if (this.typeUser == 1) {
        code = "P-5A612274HX151264PMWKYHKQ";
      } else if (this.typeUser == 2) {
        code = "P-35G601710A044681LMWKYKDQ";
      } else if (this.typeUser == 3) {
        code = "P-8RM43256TC134702LMWKYOSI";
      } else if (this.typeUser == 5) {
        code = "P-4N77539312896150MMWKYMCA";
      }

      let _context = this;

      let interval = setInterval(() => {
        _context.paypal = (window as any).paypal;

        console.log("checking paypal...");

        if (_context.paypal) {
          clearInterval(interval);

          _context.paypal
            .Buttons({
              style: {
                shape: "rect",
                color: "gold",
                layout: "vertical",
                label: "pay",
              },
              createSubscription: (data, actions) => {
                console.log("data => ", data);
                return actions.subscription.create({
                  plan_id: code,
                });
              },
              onApprove: async (data, actions) => {
                const params = {
                  iduser: this.$route.query.userId,
                  externalId: data.subscriptionID,
                  mode: "dev",
                  paymentResponse: data,
                  user: {
                    email: this.userSession.email,
                    name:
                      this.userSession.username +
                      " " +
                      this.userSession.lastname,
                  },
                };

                const response = await UserService.registerTransaction(params);
                let resdata = response.data;

                if (!resdata.error) {
                  Swal.fire({
                    title: "Register complete",
                    text: "We have sent you an email to your inbox with your password with which you can log in.",
                    icon: "success",
                    timer: 10000,
                    timerProgressBar: false,
                  }).then((result) => {
                    localStorage.clear();
                    setTimeout(() => {
                      this.$router.push({
                        path: "/",
                      });
                    }, 1000);
                  });
                  // this.$toast.success("Register complete, please log in with your account!", { position: 'top-right' })
                } else {
                  console.log(resdata.message);
                }
              },
              onCancel: (data) => {
                console.log(data);
              },
              onError: (data) => {
                console.log(data);
              },
            })
            .render("#paypal-btn-container");
        } else {
          // Puedes manejar el caso en el que paypal no está definido
          console.error(
            "PayPal no está definido. Asegúrate de que el SDK de PayPal se haya cargado correctamente."
          );
        }
      }, 250);
    },
    loadUserInfo() {
      let userInfo = localStorage.getItem("usersession");
      this.userInfo = JSON.parse(userInfo);
    },
    async loadUser() {
      const params = { id: this.$route.query.userId };
      const response = await UserService.getUser(params);
      let resdata = response.data;

      if (!resdata.error) {
      } else {
        console.log(resdata.message);
      }
    },
    submit: async function (form: any) {
      this.submitButton = false;
      form = JSON.parse(JSON.stringify(form));
      form.idrol = this.typeUser;
      const response = await UserService.register(form);

      if (!response.data.error) {
        localStorage.clear();

        this.$toast.success("Register complete, log in!", {
          position: "top-right",
        });

        this.$router.push({
          path: "/loginusers",
        });
      } else {
        this.$toast.error(response.data.message, { position: "top-right" });
        console.log(response.data.message);
      }

      this.submitButton = true;
    },
    testRegister: async function () {
      const params = {
        iduser: this.$route.query.userId,
        externalId: Math.floor(Math.random() * 99999),
        mode: "dev",
        paymentResponse: {
          id: "0VF52814937998046",
          status: "AUTHORIZED",
          amount: {
            total: "10.99",
            currency: "USD",
          },
          invoice_id: "INVOICE-123",
          seller_protection: {
            status: "ELIGIBLE",
            dispute_categories: [
              "ITEM_NOT_RECEIVED",
              "UNAUTHORIZED_TRANSACTION",
            ],
          },
          supplementary_data: {
            related_ids: {
              order_id: "29U15271MB9916641",
            },
          },
          expiration_time: "2017-10-10T23:23:45Z",
          create_time: "2017-09-11T23:23:45Z",
          update_time: "2017-09-11T23:23:45Z",
          links: [
            {
              rel: "self",
              method: "GET",
              href: "https://api.paypal.com/v2/payments/authorizations/0VF52814937998046",
            },
            {
              rel: "capture",
              method: "POST",
              href: "https://api.paypal.com/v2/payments/authorizations/0VF52814937998046/capture",
            },
            {
              rel: "void",
              method: "POST",
              href: "https://api.paypal.com/v2/payments/authorizations/0VF52814937998046/void",
            },
            {
              rel: "reauthorize",
              method: "POST",
              href: "https://api.paypal.com/v2/payments/authorizations/0VF52814937998046/reauthorize",
            },
          ],
        },
        user: {
          email: this.userSession.email,
          name: this.userSession.username + " " + this.userSession.lastname,
        },
      };

      const response = await UserService.registerTransaction(params);
      let resdata = response.data;

      if (!resdata.error) {
        Swal.fire({
          title: "Register complete",
          text: "We have sent you an email to your inbox with your password with which you can log in.",
          icon: "success",
          timer: 10000,
          timerProgressBar: false,
        }).then((result) => {
          localStorage.clear();
          setTimeout(() => {
            this.$router.push({
              path: "/",
            });
          }, 1000);
        });
        // this.$toast.success("Register complete, please log in with your account!", { position: 'top-right' })
      } else {
        console.log(resdata.message);
      }
    },
    loadRoles: async function () {
      const response = await UserService.getRoles();
      let resdata = response.data;

      if (!resdata.error) {
        let list: any = toRaw(resdata.data);
        this.listRoles = list;
        this.listRoles.forEach((role, index) => {
          if (role.name == "Admin") {
            this.listRoles.splice(index, 1);
          }
        });
      } else {
        console.log(resdata.message);
      }
    },
    getSections: async function () {
      const response = await UserService.getZoneStates();
      let resdata = response.data;

      if (!resdata.error) {
        let list: any = toRaw(resdata.data);
        list.forEach((s: any) => {
          this.stateList.push(s.name);
        });
      } else {
        console.log(resdata.message);
      }
    },
    async loadCounty() {
      const response = await UserService.getCounty(this.userForm.state);
      let resdata = response.data;

      if (!resdata.error) {
        let list: any = toRaw(resdata.data);
        this.countyList = list;
        console.log(list);
      } else {
        console.log(resdata.message);
      }
    },
    // loadCountries: async function () {
    //     const response = await UserService.getCountries()
    //     let resdata = response.data

    //     let list: any = toRaw(resdata)
    //     this.countryList = list.filter((a: any, b: any) => list.indexOf(a) === b)
    //     console.log(this.countryList)
    // }
  },
});
