
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import Loading from 'vue-loading-overlay';
import Swal from 'sweetalert2';
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'

//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            showExpenses: false,
            userSession: null,
            userSection: null,
            userRole: null,
            scenarioType: null,
            scenario: {
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            configDatePicker: {
                date: null
            },
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            paramsTable: null,
            disableRegister: false,
            filters: {
                property: null,
                score: null,
                program: null,
                year: null,
                program_name: "FHA",
                active: false
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            listUserSections: [],
            listTableProperties: [],
            listFamilyMonthExpenses: [],
            userFamilyMonthExpenses: [],
            listScoreCredits: [
                {
                    key: 0,
                    name: "300 TO 579"
                },
                {
                    key: 1,
                    name: "580 TO 669"
                },
                {
                    key: 2,
                    name: "670 TO 739"
                },
                {
                    key: 3,
                    name: "740 TO 799"
                },
                {
                    key: 4,
                    name: "800 TO 850"
                }
            ],
            listPrograms: [
                {
                    id: 0,
                    name: "FHA"
                },
                {
                    id: 1,
                    name: "CONVENTIONAL"
                },
                {
                    id: 2,
                    name: "VA"
                }
            ],
            financialInformation: {
                propertyClosingCostBuy: null,
                totalCustomerMonthlyIncome: null,
                propertyEstimatedMortgage: null,
                customers: []
            },
            selection: {
                selectedUser: null,
                selectedProperty: null,
                selectedScoreCredit: null,
                selectedProgram: null,
                selectedYears: null
            },
            videoTutorial1: null,
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            listYears: [
                {
                    id: 0,
                    name: "15 YEARS",
                    value: 15
                },
                {
                    id: 1,
                    name: "30 YEARS",
                    value: 30
                }
            ],
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            selectedTitleCompany: null,
            tables: [],
            userMLO: {
                username: null,
                lastname: null,
                phonenumber: null,
                email: null
            },
            scenarioCode: null,
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    created() {
        this.init();
    },
    computed: {
        scenarioValid: function () {
            let k = true;
            if (!this.selection.selectedUser) k = false;

            let errors = true;

            if (this.financialInformation.customers && this.financialInformation.customers.length > 0) {
                this.financialInformation.customers.forEach(a => {
                    if (!a.customer.name || !a.customer.phone || !a.customer.email) {
                        errors = false;
                    }
                })
            }

            k = errors;

            return k;
        },
        sortOutRatio: function () {
            let interest_rate = 0;
            let years = 1;
            if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
                years = this.selection.selectedYears.value * 12
            }
            if (this.selection.selectedUser) {
                interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
            }
            return Math.pow(1 + ((interest_rate / 12) / 100), years) * ((interest_rate / 12) / 100);
        },
        sortOutRatio2: function () {
            let interest_rate = 0;
            let result = 0
            let years = 1;
            if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
                years = this.selection.selectedYears.value * 12
            }
            if (this.selection.selectedUser) {
                interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
                result = Math.pow(1 + (interest_rate / 12) / 100, years) - 1
            }
            return result
        },
        sortOutFactor: function () {
            let result = 0
            if (this.sortOutRatio !== 0 && this.sortOutRatio2 !== 0) {
                result = this.sortOutRatio / this.sortOutRatio2
            }
            return result
        },
    },
    methods: {
        init: function () {
            this.scenarioCode = "DTI-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", "");

            this.getSession();
            this.loadSectionUsers();
            this.getScenarioType("RATIO");
            // this.getTableTemplate();

            this.addScenarioCustomer();
        },
        submitFormula: async function (property: string, event: any = null) {
            let monthlyTotal = this.financialInformation.totalCustomerMonthlyIncome
            let totalExpenses = this.selection.selectedUser.totalFamilyMonthExpenses
            let backend_ratio = parseFloat(this.selection.selectedUser.backend_ratio.replace('%', ''))

            let DTIValue = monthlyTotal * (backend_ratio / 100)
            let DTIValueNet = DTIValue - totalExpenses

            this.financialInformation.propertyEstimatedMortgage = DTIValueNet / this.sortOutFactor
        },
        incomeTypeTableEmpty: function (d) {
            let error = false;
            for (let w in d) {
                if (!d[w]) {
                    error = true;
                    break;
                }
            }

            return error;
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        generateMail: function () {
            let ref: any = this.$refs.html2PdfLoan;
            ref.generatePdf();
        },
        beforeFileGenerated: function (event) {
            console.log(event);
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessageMAILSCENARIO");

                console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        addScenarioCustomer: function () {
            if (this.financialInformation.customers.length == 4) return;

            let customer = {
                customer: { ...this.customer },
                incomeType: {
                    totalMonthlyIncome: null,
                    hours: [
                        {
                            paymentForHour: null,
                            hoursForWeek: null,
                            daysForWeek: null,
                            weeksForMonth: null,
                            monthsForYear: null,
                            monthlyIncome: null
                        }
                    ],
                    week: [
                        {
                            paymentForWeek: null,
                            weeksForMonth: null,
                            monthsForYear: null,
                            monthlyIncome: null
                        }
                    ],
                    biweekly: [
                        {
                            paymentForWeekly: null,
                            byWeeklyForYear: null,
                            monthsForYear: null,
                            monthlyIncome: null
                        }
                    ],
                    months: [
                        {
                            paymentForMonths: null,
                            monthsForYear: null,
                            monthlyIncome: null
                        }
                    ]
                }
            }

            this.financialInformation.customers.push(customer);
        },
        calculateTotalCustomerMonthlyIncome: function () {
            this.financialInformation.totalCustomerMonthlyIncome = 0;

            this.financialInformation.customers.forEach(a => {
                if (!isNaN(parseFloat(a.incomeType.totalMonthlyIncome))) {
                    this.financialInformation.totalCustomerMonthlyIncome += a.incomeType.totalMonthlyIncome ? parseFloat(a.incomeType.totalMonthlyIncome) : 0;
                }
            })

            console.log(this.financialInformation.totalCustomerMonthlyIncome);

            this.financialInformation.totalCustomerMonthlyIncome = !isNaN(parseFloat(this.financialInformation.totalCustomerMonthlyIncome)) ? parseFloat(this.financialInformation.totalCustomerMonthlyIncome).toFixed(0) : 0
        },
        calculateTotalMonthlyIncome: function (index) {
            let total: any = 0.0;
            for (let w in this.financialInformation.customers[index].incomeType) {
                if (w != "totalMonthlyIncome") {
                    this.financialInformation.customers[index].incomeType[w].forEach(a => {
                        if (!isNaN(parseFloat(a.monthlyIncome))) {
                            total += parseFloat(a.monthlyIncome);
                        }
                    })
                }
            }

            console.log(total);

            this.financialInformation.customers[index].incomeType.totalMonthlyIncome = isNaN(parseFloat(total)) ? 0 : parseFloat(total).toFixed(0);
        },
        removeIncomeType: function (type, index, arrayIndex) {
            this.financialInformation.customers[arrayIndex].incomeType[type].splice(index, index + 1);
        },
        calculateIncomeType: function (type, index, arrayIndex) {
            let errors = false;
            let result: any = null;

            for (let w in this.financialInformation.customers[arrayIndex].incomeType[type][index]) {
                if (w != "monthlyIncome" && !this.financialInformation.customers[arrayIndex].incomeType[type][index][w]) {
                    errors = true;
                }
            }

            if (errors) return;

            const { paymentForHour, hoursForWeek, daysForWeek, weeksForMonth, monthsForYear, paymentForWeek, paymentForWeekly, byWeeklyForYear, paymentForMonths } = this.financialInformation.customers[arrayIndex].incomeType[type][index];
            let a: any = 0;
            let b: any = 0;
            let c: any = 0;
            let d: any = 0;

            switch (type) {
                case "hours":
                    a = paymentForHour * hoursForWeek;
                    b = a * daysForWeek;
                    c = (((weeksForMonth + 0.33) * 12) * b) / 12;
                    d = (monthsForYear * c) / 12;

                    result = d.toFixed(0);
                    break;
                case "week":
                    a = (weeksForMonth + 0.33) * paymentForWeek;
                    b = monthsForYear * a;
                    c = b / 12;

                    result = c.toFixed(0);
                    break;
                case "biweekly":
                    a = (byWeeklyForYear + 0.166) * paymentForWeekly;
                    b = monthsForYear * a;
                    c = b / 12;

                    result = c.toFixed(0);
                    break;
                case "months":
                    a = (paymentForMonths * monthsForYear);
                    b = a / 12;

                    result = b.toFixed(0);
                    break;
                default:
                    result = 0;
                    break;
            }

            console.log(result);

            this.financialInformation.customers[arrayIndex].incomeType[type][index].monthlyIncome = result;
        },
        addIncomeType: function (type, arrayIndex) {
            let c: any = { ...this.financialInformation.customers[arrayIndex].incomeType[type][0] }
            let n: any = {};
            Object.keys(c).forEach((j) => {
                n[j] = null;
            })

            if (this.financialInformation.customers[arrayIndex].incomeType[type].length < 3) {
                this.financialInformation.customers[arrayIndex].incomeType[type].push(n);
            }
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
            }
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios.results = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            const params = { name: name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.userSession = session;
            } else {
                UserService.logout("Session expired!")
            }
        },
        addUserExtraTables: async function () {

            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {
                    if (u.tabletype.includes("mlo")) {
                        u.fha = null;
                        u.isManuallyAdded = true;
                        u.interes_rate = null;
                        u.minimum_down_paymentlower = null;
                        u.minimum_down_paymenthigher = null;
                        u.average_mip_pmi = null;
                        u.backend_ratio = null;
                        u.maximum_loan = null;
                        u.listFamilyMonthExpenses = [];
                        u.familyMonthExpenses = [];
                        u.familyMonthExpensesEmpty = false;
                        u.z = this.listUserSections.length;
                        u.totalFamilyMonthExpenses = 0;
                        u.minimum_down_payment = null;

                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && !ut.tabletype.includes("titlecompany"))

                        u.tables.forEach(usertable => {
                            if (usertable.tabletype == "mlo_primary") {
                                let tabledata: any[] = usertable.tabledata.find(a => a.id == '11')?.data;
                                tabledata = tabledata.map((w: any) => { return { table_text: w.table_text ? w.table_text : "EMPTY", table_housing_expenses: w.table_housing_expenses?.length == 0 || isNaN(parseFloat(w.table_housing_expenses)) ? 0 : parseFloat(w.table_housing_expenses) } });

                                if (tabledata) {
                                    let l: any = [];
                                    tabledata.forEach((w, i) => {

                                        let o = [];
                                        let t: any = {};
                                        for (let j in w) {
                                            let b: any = {};
                                            b.user = u.z;
                                            b.z = i;
                                            b.id = j;
                                            b.type = j == 'table_text' ? "text" : "number";
                                            b.value = w[j];
                                            o.push(b)
                                        }

                                        t.index = i;
                                        t.values = o;

                                        // this.listFamilyMonthExpenses.push(t);

                                        u.familyMonthExpenses.push(t);

                                        let y: any = { ...t }
                                        let k: any = [...o]

                                        let m = this.userFamilyMonthExpenses.find(u => u.index == i);
                                        k = o.filter((o: any) => o.id != "table_housing_expenses")
                                        y.values = t.values.filter((o: any) => o.id != "table_housing_expenses");

                                        if (m) {
                                            m.values.push(...k);
                                        }
                                        else {
                                            this.userFamilyMonthExpenses.push(y);
                                        }

                                    })

                                    u.listFamilyMonthExpenses.push(...tabledata);
                                    u.ctfms = function () {
                                        u.totalFamilyMonthExpenses = u.listFamilyMonthExpenses.map((j: any) => { return isNaN(parseFloat(j.table_housing_expenses)) ? 0 : parseFloat(j.table_housing_expenses) }).reduce((a: any, b: any) => a + b, 0);
                                        u.showTotalFamily = true;
                                    }

                                }
                            }

                            let tabletype = usertable.tabletype;

                            let type = {
                                id: btoa(tabletype),
                                name: tabletype.split("_")[1][0].toUpperCase() + tabletype.split("_")[1].substring(1, tabletype.split("_")[1].length)
                            }

                            if (!this.listTableProperties.find(a => a.id == type.id)) {
                                this.listTableProperties.push(type);
                            }
                        })

                    }

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("mlo")) this.listUserSections.push(user);
                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                })

                console.log(this.listUserSections);
                this.listTableProperties = this.listTableProperties.filter(a => !["investment", "foreign"].includes(a.name.toLowerCase())).map(a => { return { ...a, name: ["primary", "second"].includes(a.name.toLowerCase()) ? a.name + " Property" : a.name + " Program" } })


            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            // if(this.listUserSections.find(a => a.isManuallyAdded)) {
            //     this.userFamilyMonthExpenses.forEach(a => {
            //         if((a.values.length-1) != this.listUserSections.length) {
            //             a.values.pop();
            //         }
            //     })
            // }

            this.loaderConfig.isLoading = false;
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.userSection = toRaw(response.data);
                if (this.userSection.mlo > 0) {
                    this.listUserSections = this.userSection.mlo_users
                    this.listTitleCompanies = toRaw(this.userSection.titlecompany_users);
                    this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    this.listInspectors = toRaw(this.userSection.inspector_users);
                    this.listInspectors = this.listInspectors.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })
                    if (this.listUserSections.length > 0) {
                        this.listUserSections = this.listUserSections.map((u, z) => {

                            u.fha = null;
                            u.interes_rate = null;
                            u.minimum_down_paymentlower = null;
                            u.minimum_down_paymenthigher = null;
                            u.average_mip_pmi = null;
                            u.backend_ratio = null;
                            u.isManuallyAdded = false;
                            u.maximum_loan = null;
                            u.listFamilyMonthExpenses = [];
                            u.familyMonthExpenses = [];
                            u.familyMonthExpensesEmpty = false;
                            u.z = z;
                            u.totalFamilyMonthExpenses = 0;
                            u.minimum_down_payment = null;


                            if (u.tables && u.tables.length > 0) {
                                u.tables = u.tables.map((t: any) => {
                                    let tables = JSON.parse(t.tabledata);

                                    let formatTables: any[] = [];

                                    if (tables.constructor === Array) {
                                        tables.map((c: any) => {

                                            if (c.convert) {
                                                let listdata: any = []

                                                c.data.forEach((v: any) => {
                                                    let j: any = {}

                                                    v.map((e: any) => {
                                                        let o: any = {}
                                                        e.values.forEach((w: any) => {
                                                            o[`${e.name}_${w.name}`] = w.value
                                                        })

                                                        return o
                                                    }).forEach((p: any) => {
                                                        for (let f in p) {
                                                            j[f] = p[f]
                                                        }
                                                    })

                                                    v = [j]
                                                    listdata.push(j)
                                                })

                                                c.data = listdata
                                            }

                                            formatTables.push(c);
                                        })
                                    }



                                    if (t.tabletype == "mlo_primary") {
                                        let data: any[] = formatTables.find(a => a.id == '11')?.data;
                                        data = data.map((w: any) => { return { table_text: w.table_text ? w.table_text : "EMPTY", table_housing_expenses: w.table_housing_expenses?.length == 0 || isNaN(parseFloat(w.table_housing_expenses)) ? 0 : parseFloat(w.table_housing_expenses) } });

                                        if (data) {
                                            let l: any = [];
                                            data.forEach((w, i) => {

                                                let o = [];
                                                let t: any = {};
                                                for (let j in w) {
                                                    let b: any = {};
                                                    b.user = z;
                                                    b.z = i;
                                                    b.id = j;
                                                    b.type = j == 'table_text' ? "text" : "number";
                                                    b.value = w[j];
                                                    o.push(b)
                                                }

                                                t.index = i;
                                                t.values = o;

                                                // this.listFamilyMonthExpenses.push(t);

                                                u.familyMonthExpenses.push(t);

                                                let y: any = { ...t }
                                                let k: any = [...o]

                                                let m = this.userFamilyMonthExpenses.find(u => u.index == i);
                                                k = o.filter((o: any) => o.id != "table_housing_expenses")
                                                y.values = t.values.filter((o: any) => o.id != "table_housing_expenses");

                                                if (m) {
                                                    m.values.push(...k);
                                                }
                                                else {
                                                    this.userFamilyMonthExpenses.push(y);
                                                }

                                            })

                                            u.listFamilyMonthExpenses.push(...data);
                                            u.ctfms = function () {
                                                u.totalFamilyMonthExpenses = u.listFamilyMonthExpenses.map((j: any) => { return isNaN(parseFloat(j.table_housing_expenses)) ? 0 : parseFloat(j.table_housing_expenses) }).reduce((a: any, b: any) => a + b, 0);
                                                u.showTotalFamily = true;
                                            }

                                        }

                                    }

                                    let tabletype = {
                                        id: btoa(t.tabletype),
                                        name: t.tabletype.split("_")[1][0].toUpperCase() + t.tabletype.split("_")[1].substring(1, t.tabletype.split("_")[1].length)
                                    }

                                    if (!this.listTableProperties.find(a => a.id == tabletype.id)) {
                                        this.listTableProperties.push(tabletype);
                                    }

                                    return { ...t, id_tabletype: tabletype.id, tabledata: tables };
                                })

                            }

                            if (u.familyMonthExpenses.length == 0) {
                                u.familyMonthExpensesEmpty = true;
                            }

                            return toRaw(u)
                        });

                        if (this.listTableProperties.length > 0) {
                            this.filters.property = this.listTableProperties[0].id;
                        }


                    }
                }

                this.addUserExtraTables();
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        filterTable: function () {
            let f: any = {
                property: this.listTableProperties.find(a => a.id == this.filters.property),
                score: this.filters.score,
                program: this.listPrograms.find(a => a.id == this.filters.program),
                year: this.listYears.find(a => a.id == this.filters.year)
            }

            f.property = this.listTableProperties[0];

            let q = 0

            for (let a in f) {
                if (f[a] != undefined) {
                    q++;
                }
            }

            if (q == 4) {
                this.filters.active = true;
                this.filters.program_name = f.program.name;
                this.listUserSections.forEach((user: any) => {

                    user.fha = null;
                    user.interes_rate = "-";
                    user.minimum_down_paymentlower = "-";
                    user.minimum_down_paymenthigher = "-";
                    user.average_mip_pmi = "-";
                    user.backend_ratio = "-";
                    user.maximum_loan = "-";
                    user.minimum_down_payment = "-";

                    if (user.tables) {
                        let t: any = user.tables.find((b: any) => b.id_tabletype == f.property.id)

                        let g = t.tabledata.find(k => k.id == 12);
                        if (g) {
                            g.data.forEach(e => {
                                if (e.table_name == f.program.name) {
                                    user.minimum_down_payment = e.table_value;
                                }
                            })
                        }

                        if (t) {
                            let y: any = t.tabledata.find((o: any) => o.id == (f.year.id + 1).toString())
                            if (y) {
                                const w = (f.program.name.toLowerCase() == "conventional" ? "coventional" : f.program.name.toLowerCase())

                                y.data.forEach((e: any) => {
                                    if (e[w + "_credit"]) {
                                        let min = parseFloat(e[w + "_credit"].replaceAll("-", "TO").split("TO")[0].trim());
                                        let max = parseFloat(e[w + "_credit"].replaceAll("-", "TO").split("TO")[1].trim());
                                        let s = parseFloat(f.score)

                                        if (s >= min && s <= max) {
                                            user.interes_rate = e[w + "_percent"] + "%"

                                            t.tabledata.forEach((j: any) => {
                                                let k = j.data.find((a: any) => a?.table_name?.toLowerCase() == w || a?.table_name?.toLowerCase().includes(f.program.name.toLowerCase()))
                                                if (k) {
                                                    if (k?.table_minimmum_down_payment) user.minimum_down_paymentlower = k?.table_minimmum_down_payment + "%";
                                                    if (k?.table_score_credit_higher_than_b) user.minimum_down_paymenthigher = k?.table_score_credit_higher_than_b + "%";
                                                    // if (k?.table_mip || k?.table_pmi) user.average_mip_pmi = (k?.table_pmi ? k?.table_pmi : k?.table_mip) + "%";
                                                    if (k?.table_backend_ratio) user.backend_ratio = k?.table_backend_ratio + "%";
                                                    if (k?.table_input_mla) user.maximum_loan = "$" + k?.table_input_mla;

                                                    if (k?.table_mip || k?.table_pmi) {
                                                        // if (this.filters.program_name == "FHA" || this.filters.program_name == "VA") user.average_mip_pmi = k?.table_mip + "%";
                                                        // else user.average_mip_pmi = k?.table_pmi + "%";
                                                        user.average_mip_pmi = k?.table_pmi + "%";
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })

                            }
                        }
                    }
                })
            }

        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        submitTables: async function (type: any = null) {

            this.loaderConfig.isLoading = true;
            this.disableRegister = true;

            this.paramsTable = {};
            this.paramsTable.tabletype = type
            let c = 0;

            for (let w of this.listUserSections) {
                this.paramsTable = { ...toRaw(w.tables.find((a: any) => a.tabletype == type)) };
                this.paramsTable.tabledata = JSON.stringify(toRaw(this.paramsTable.tabledata))

                const response = await UserTableService.register(toRaw(this.paramsTable));

                if (!response.data.error) {
                    c++;
                }
            }

            if (c == this.listUserSections.length) {

                // this.$toast.success("Tables saved!", { position: 'top-right' })
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Data saved successfully',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else {
                this.$toast.error("Error!", { position: 'top-right' })
            }

            this.disableRegister = false;
            this.loaderConfig.isLoading = false;
        },
        fillData: function (u: any) {
            u.value = u.value.toString().length == 0 ? 0 : parseFloat(u.value)
            this.listUserSections[u.user].tables.find((t: any) => t.tabletype == "mlo_primary").tabledata.find((w: any) => w.id == "11").data[u.z][u.id] = u.value;
            this.listUserSections[u.user].listFamilyMonthExpenses[u.z][u.id] = u.value;
            console.log(this.listUserSections[u.user]);

            console.log(u);
        },
        getItData: function (u: any) {
            this.listFamilyMonthExpenses = u.familyMonthExpenses;
            this.loadData(u);
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
            this.financialInformation.propertyClosingCostBuy = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata.find((e: any) => e.id == "1").data.find((j: any) => j.table_name == "CLOSING CLOSE").table_closing_close;
        },
        loadData: function (user: any) {
            console.log(user);
            this.selection.selectedUser = { ...user };

            this.selection.selectedUser.ctfms = function (user: any = null) {
                user.totalFamilyMonthExpenses = user.listFamilyMonthExpenses.map((j: any) => { return isNaN(parseFloat(j.table_housing_expenses)) ? 0 : parseFloat(j.table_housing_expenses) }).reduce((a: any, b: any) => a + b, 0);
                user.showTotalFamily = true;
            }

            this.selection.selectedProperty = this.listTableProperties.find(c => c.id == this.filters.property);
            this.selection.selectedProgram = this.listPrograms.find(c => c.id == this.filters.program);
            this.selection.selectedYears = this.listYears.find(c => c.id == this.filters.year);

            Swal.fire({
                title: "Message",
                text: this.selection.selectedUser.username + " " + this.selection.selectedUser.lastname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;
            $event.disabled = true;
            let errors = false;

            let params: any = {
                code: this.scenarioCode,
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.financialInformation.customers[0].customer.email,
                user_phonenumber: this.financialInformation.customers[0].customer.phone,
                user_name: this.financialInformation.customers[0].customer.name,
                data: {
                    mortgage_information: { ...toRaw(this.selection), ...this.filters },
                    financial_information: { ...this.financialInformation }
                },
                id_user: this.userSession.iduser
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (response.error) errors = true;

            if (!errors) {
                Swal.fire({
                    title: "Realtor",
                    text: "Data saved!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log("")
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})
