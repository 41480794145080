

import { defineComponent, toRaw } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UserService from '@/shared/services/user.service';
import router from '@/router';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useToast } from 'vue-toast-notification';
import Loading from 'vue-loading-overlay';
import { environment } from '../../environment'


export default defineComponent({
    data() {
        return {
            $toast: useToast(),
            listRoles: [],
            URL: environment.URL_UPLOADS,
            _window: null,
            userSession: null,
            userSection: null,
            userRole: null,
            refreshSession: null,
            usersession: null,
            isinspection: false,
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            filters: {
                date: null,
                read: -1,
            },
            configDatePicker: {
                date: null
            },
            listMails: [],
            listUserSections: [],
            mail: {
                mailfrom: "",
                mailto: "",
                subject: "",
                message: "",
                inspection_address: null,
                inspection_date: new Date(),
                inspection_hour: null,
                filemail: null
            },
            mailselected: null
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Loading
    },
    created() {
        this.init()
        this.getSession();
        this._window = window;
        this.loadSectionUsers();
    },
    methods: {
        init: function () {
            this.loadRoles()
        },
        formatInspectionDate: (date: any) => {
            return moment(date).format("DD/MM/YYYY")
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.usersession = session

                this.loadMails();
            } else {
                clearInterval(this.refreshSession)
                UserService.logout("Session expired!")
            }
        },
        cleanMail: function () {
            this.mail = {
                mailfrom: "",
                mailto: "",
                subject: "",
                message: "",
                inspection_address: null,
                inspection_date: new Date(),
                inspection_hour: null,
                filemail: null
            }
        },
        loadRoles: async function () {
            this.loaderConfig.isLoading = true;
            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list
                this.listRoles.forEach((role, index) => {

                    role.abb = role.name.toLowerCase().replaceAll(" ", "")

                    if (role.name == "MLO") {
                        role.name = "MORTGAGE LOAN ORIGINATOR"
                    }

                    role.name = role.name.toUpperCase()
                })
            }
            else {
                console.log(resdata.message)
            }
            this.loaderConfig.isLoading = false;
        },
        loadMails: async function (loader: boolean = true) {
            if (loader) this.loaderConfig.isLoading = true;
            let params = {
                ...this.filters,
                email: this.usersession.email,
                type: this.usersession.role == "Realtor" ? "from" : "to"
            }

            let response = (await UserService.getMails(params)).data;

            if (!response.error) {
                this.listMails = toRaw(response.data);
                this.listMails = this.listMails.map((e) => {
                    return { ...e, selected: false, date1: e.maildate ? moment(e.maildate).format("MM/DD/YYYY") : null, date2: e.maildate ? moment(e.maildate).format("MMMM DD") : null, inspectiondate: e.inspection_date ? moment(e.inspection_date).format("MM/DD/YYYY") : null }
                })

                console.log(toRaw(this.listMails))
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.usersession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.userSection = toRaw(response.data);
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        sendMail: async function (event: any) {
            this.loaderConfig.isLoading = true;
            event.target.disabled = true
            let formdata = new FormData();
            formdata.append("mailfrom", this.usersession.email);
            formdata.append("mailto", this.mail.mailto);
            formdata.append("subject", this.mail.subject);
            formdata.append("message", this.mail.message);
            formdata.append("filemail", this.mail.filemail);
            formdata.append("inspection_address", "");
            formdata.append("inspection_date", "");
            formdata.append("inspection_hour", "");

            if (this.isinspection) {
                formdata.append("inspection_address", this.mail.inspection_address);
                formdata.append("inspection_date", moment(this.mail.inspection_date).format("YYYY-MM-DD"));
                formdata.append("inspection_hour", moment(this.mail.inspection_hour).format("HH:mm"));
            }

            let response = (await UserService.sendMail(formdata)).data;

            if (!response.error) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                });

                this.cleanMail();
                this._window.closeAllModals();
                this.loadMails();
            }
            else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

            event.target.disabled = false
            this.loaderConfig.isLoading = false;
        },
        attachFile: async function () {
            let a = document.createElement("input")
            a.type = "file"
            a.click()

            a.addEventListener("change", ($event: any) => {
                if ($event.target.files.length > 0) {
                    this.mail.filemail = $event.target.files[0]
                }
            })
        },
        selectMail: async function (mail: any) {
            this.mailselected = mail;

            if (this.usersession.role != "Realtor") {
                if (this.mailselected.mailopen != 1) {
                    let params = new FormData();
                    params.append("idmail", this.mailselected.idmail);
                    params.append("mailfrom", this.mailselected.mailfrom);
                    params.append("mailto", this.mailselected.mailto);
                    params.append("subject", this.mailselected.subject);
                    params.append("message", this.mailselected.message);
                    params.append("mailopen", "1");


                    let response = (await UserService.updateMail(params)).data

                    if (!response.error) {
                        this.loadMails(false);
                    }
                    else {
                        console.log(response.message)
                    }
                }
            }
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.filters.date = {
                    date1: moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    date2: moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
                }
            }
            else {
                this.filters.date = null;
            }

            this.loadMails();
        },
        changeRead: function () {
            if (this.filters.read != -1) {
                this.loadMails();
            }
        },
        selectAllMails: function (event: any) {
            if (event.target.checked) {
                this.listMails.forEach((a) => {
                    a.selected = true;
                })
            }
            else {
                this.listMails.forEach((a) => {
                    a.selected = false;
                })
            }
        },
        deleteMail: async function () {
            this.loaderConfig.isLoading = true;
            let params = this.listMails.filter(function (a) { return a.selected == true })
            params = params.map((e) => { return e.idmail })
            let paramsextra = { type: this.usersession.role == "Realtor" ? "from" : "to" }

            let response = (await UserService.deleteMails(params.toString(), paramsextra)).data

            if (!response.error) {
                let swal = Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                });

                swal.then(() => {
                    this.loadMails();
                })

            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
            }
            this.loaderConfig.isLoading = false;
        },
        modalListUsers: function (t: any) {
            this._window.modalclose("#sendMailModal");
            this._window.openModal("exampleModalMLO");

            if (t == 1) {
                this.listUserSections = this.userSection.mlo_users
            }
            else if (t == 2) {
                this.listUserSections = this.userSection.inspector_users
            }
            else {
                this.listUserSections = this.userSection.titlecompany_users
            }

        },
        backModal: function () {
            this._window.modalclose("#exampleModalMLO");
            this._window.openModal("sendMailModal");
        }
    }
})
