import axios from "axios";
import router from "@/router";
import { useToast } from "vue-toast-notification";
import { environment } from "@/environment";

export default class UserService {
    constructor() {}

    private static API_URL = environment.URL_API;

    static async login(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "login", params)

        return result;
    }

    static async registerSupportMessage(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "support/messages", params)

        return result;
    }

    static async getSupportMessages(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + `support/messages?name=""${params.date ? "&from=" + params.date.date1 + "&to=" + params.date.date2:""}`)

        return result;
    }

    static async deleteContactForm(params: any) {
        let result = null;
        result = await axios.put(this.API_URL + "contact/" + params.forms.toString(), params)

        return result;
    }

    static async sendUserMail(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "user/sendmail", params)

        return result;
    }

    static async getScenarioTypes(params: any) {
        let result = null;
        params = new URLSearchParams(params);
        result = await axios.get(this.API_URL + "scenarios/types?" + params)

        return result;
    }

    static async getScenarioUsers(params: any) {
        let result = null;
        params = new URLSearchParams(params).toString().split("&").filter(a => (a.split("=")[1] && a.split("=")[1].length > 0)).toString().replaceAll(",", "&");
        result = await axios.get(this.API_URL + "scenarios/users?" + params)

        return result;
    }

    static async getScenarios(params: any) {
        let result = null;
        params = new URLSearchParams(params).toString().split("&").filter(a => (a.split("=")[1] && a.split("=")[1].length > 0)).toString().replaceAll(",", "&");
        result = await axios.get(this.API_URL + "scenarios?" + params)

        return result;
    }

    static async saveScenario(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "scenarios", params)

        return result;
    }

    static async forgotPassword(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "user/forgotpassword", params)

        return result;
    }

    static async getUsers(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + `user?page=${params.page}&pageSize=${params.pageSize}&usertype=${params.usertype}&state=${params.state}&country=${params.country}&section=${params.section}&zipcode=${params.zipcode}${params.from ? '&from='+params.from:''}${params.to ? '&to='+params.to:''}${params.inspectiontype ? '&inspectiontype='+params.inspectiontype:''}`)

        return result;
    }

    static async register(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "user", params)

        return result;
    }

    static async update(params: any) {
        let result = null;
        result = await axios.put(this.API_URL + "user", params)

        return result;
    }

    static async updateStatus(params) {
        let result = null;
        result = await axios.put(this.API_URL + "user/status", params)

        return result;
    }

    static async registerTransaction(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "transactions", params)

        return result;
    }

    static async getSections() {
        let result = null;
        result = await axios.get(this.API_URL + "user/sections")

        return result;
    }


    static async getRoles() {
        let result = null;
        result = await axios.get(this.API_URL + "rol")

        return result;
    }

    static async getUser(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + "user/id/" + params.iduser)

        return result;
    }

    static async getSectionUsers(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + "user/sections/" + params.zipcode)

        return result;
    }

    static async getUserCountries() {
        let result = null;
        result = await axios.get(this.API_URL + "user/country")

        return result;
    }

    static async getUserStates() {
        let result = null;
        result = await axios.get(this.API_URL + "user/state")

        return result;
    }

    static async getCounty(state: string) {
        let result = null;
        result = await axios.get(this.API_URL + "user/county?state=" + state )

        return result;
    }

    static async getCountySections(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + "user/county/sections?state=" + params.state + "&county=" + params.country)

        return result;
    }

    static async getZoneStates() {
        let result = null;
        result = await axios.get(this.API_URL + "user/states")

        return result;
    }

    static async getUserZipCodes() {
        let result = null;
        result = await axios.get(this.API_URL + "user/zip")

        return result;
    }

    static async getMails(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + `mail?type=${params.type}&email=${params.email}${params.date ? "&from=" + params.date.date1 + "&to=" + params.date.date2:""}${params.read != null && params.read != -1 ? "&read=" + params.read:""}`)

        return result;
    }

    static async sendMail(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + `mail`, params)

        return result;
    }

    static async getContactForms(params: any) {
        let result = null;
        params = new URLSearchParams(params).toString().replace("usertype", "role").split("&").map(a => a.replace("0", "").split("=")[1] ? a:a.replace("0", "")).filter(a => a.split("=")[1]).toString().replaceAll(",", "&");
        result = await axios.get(this.API_URL + `contact?${params}`)

        return result;
    }

    static async sendContactForm(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + `contact`, params)

        return result;
    }

    static async updateMail(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + `mail/${params.get("idmail")}`, params)

        return result;
    }

    static async deleteMails(params: any, paramsextra: any) {
        let result = null;
        result = await axios.put(this.API_URL + `mail/${params}`, paramsextra)

        return result;
    }

    static logout(message : any = "Sesion closed!") {

        let $toast = useToast()
        localStorage.clear()
        $toast.error(message, { position: 'top-right' })
        router.push({
            path: "/"
        })
    }

    static async getTitleCompany(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + "user/title-company?zipcode=" + params.zipcode)
        return result;
    }
    
    static async getInspectorServices(params: any) {
        let result = null;
        result = await axios.get(this.API_URL + "user/inspector-services?inspectorID=" + params.inspectorID)
        return result;
    }
    static async saveInspectorServices(params: any) {
        let result = null;
        result = await axios.post(this.API_URL + "user/inspector-services", params)
        return result;
    }
}
