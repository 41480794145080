
import { defineComponent, toRaw } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import UserService from "@/shared/services/user.service";
import router from "@/router";

export default defineComponent({
  data() {
    return {
      listRoles: [],
      productSelected: "PRODUCTS",
      videoLink: null,
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  created() {
    this.init();
  },
  methods: {
    init: function () {
      this.loadRoles();
    },
    loadRoles: async function () {
      const response = await UserService.getRoles();
      let resdata = response.data;

      if (!resdata.error) {
        let list: any = toRaw(resdata.data);
        this.listRoles = list;
        this.listRoles.forEach((role, index) => {
          role.abb = role.name.toLowerCase().replaceAll(" ", "");

          if (role.name == "MLO") {
            role.name = "MORTGAGE LOAN ORIGINATOR";
          }

          role.name = role.name.toUpperCase();
        });
      } else {
        console.log(resdata.message);
      }
    },
    openModal: function (modalname: string, option: any) {
      let _window: any = window;
      let bootstrap = _window.bootstrap;

      this.productSelected = option;

      const modal = new bootstrap.Modal("#" + modalname);
      modal.show();

      const modalopen = document.getElementById(modalname);
      modalopen.addEventListener("hidden.bs.modal", (event) => {
        this.productSelected = null;
      });
    },
  },
});
