
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import Loading from 'vue-loading-overlay';
import { defineComponent, toRaw } from 'vue';
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'

//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    props: {
        foreign: null,
        header: null
    },
    data() {
        return {
            v$: useVuelidate(),
            userSession: null,
            userTables: null,
            scenarioType: null,
            videoTutorial1: null,
            scenario: {
                user: null,
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            configDatePicker: {
                date: null
            },
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            filters: {
                property: null,
                score: null,
                program: null,
                year: null,
                program_name: "FHA",
                active: false
            },
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            listMlos: [],
            listUserSections: [],
            listTableProperties: [],
            listTablePropertiesMlo: [],
            listPrograms: [
                {
                    id: 0,
                    name: "FHA"
                },
                {
                    id: 1,
                    name: "CONVENTIONAL"
                },
                {
                    id: 2,
                    name: "VA"
                }
            ],
            listYears: [
                {
                    id: 0,
                    name: "15 YEARS",
                    value: 15
                },
                {
                    id: 1,
                    name: "30 YEARS",
                    value: 30
                }
            ],
            selectedTitleCompany: null,
            selection: {
                selectedUser: null,
                selectedProperty: null,
                selectedScoreCredit: null,
                selectedProgram: null,
                selectedYears: null
            },
            financialInformation: {
                mortgage: 15000,
                //
                propertyType: null,
                propertyAddresses: null,
                propertyPrice: null,
                //
                propertyEstimateLoan: null,
                property: null,
                propertyInterestRate: null,
                propertyLoanYears: null,
                propertyLoanProgram: null,
                propertyDownPayment: null,
                propertyPmiMip: null,
                //
                propertyMonthlyPayment: null,
                //
                propertyClosingCostBuy: null,
                propertyBrokerPayment: null,
                //
                propertyAnnualPropertyTaxes: null,
                propertyMonthlyPropertyTaxes: null,
                propertyAnnualPropertyInsurance: null,
                propertyMonthlyPropertyInsurance: null,
                propertyAnnualStateTaxes: null,
                propertyMonthlyStateTaxes: null,
                propertyAnnualFederalTaxes: null,
                propertyMonthlyFederalTaxes: null,


                propertyAssociationPayment: null,
                propertyPaymentMonthlyServices: null,
                propertyOtherMonthlyPayment: null,
                //
                propertyMonthlyPercentageOccupancy: null,
                propertyMonthlyPercentageOccupancyValue: null,
                propertyRentDay: null,
                propertyEstimateMonthlyIncome: null,
                //
                propertyEstimateCapitalGainPlusvalia: null,
                propertyAmortizationOfDebtCapital: null,
                //
                propertyAverageAnnualNetProfit: null,
                propertyAverageNetProfitYears: null,
                propertyTaxes: null,
                stateTaxes: null,
                propertyInsurance: null,
                propertyFloodInsurance: null,
                association: 0,
                otherMontlhyPayment: null
            },
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            tables: [],
            userMLO: {
                username: null,
                lastname: null,
                phonenumber: null,
                email: null
            },
            tablesMLO: [],
            titleTable: "Primary",
            tableTypesMLO: [
                {
                    key: "mlo_primary",
                    name: "Primary",
                    visible: true,
                    tables: []
                },
                {
                    key: "mlo_second",
                    name: "Second",
                    visible: false,
                    tables: []
                },
                {
                    key: "mlo_investment",
                    name: "Investment",
                    visible: false,
                    tables: []
                },
                {
                    key: "mlo_special",
                    name: "Special",
                    visible: false,
                    tables: []
                }
            ],
            pmi: 0,
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    created() {
        this.init();
    },
    computed: {
        // pmi() {
        //     let j = 0;
        //     if (this.selection.selectedUser) {
        //         this.financialInformation.propertyPmiMip = parseFloat(this.selection.selectedUser.average_mip_pmi) * this.financialInformation.mortgage / 12;

        //         let l = this.financialInformation.propertyPrice * 0.20;
        //         this.financialInformation.propertyPmiMip = this.financialInformation.propertyDownPayment < l ? this.financialInformation.propertyPmiMip : 0;
        //     }
        //     else {
        //         this.financialInformation.propertyPmiMip = 0;
        //     }

        //     j = this.financialInformation.propertyPmiMip;

        //     return j;
        // },
        propertyPrice() {
            let value = 0;
            if (this.financialInformation.propertyPrice) {
                value = parseFloat(this.financialInformation.propertyPrice)
            }
            return value
        },
        scenarioValid: function () {
            let k = true;

            if (!this.selection.selectedUser || !this.selectedTitleCompany) k = false;
            if (this.v$.customer.$invalid) k = false;

            return k;
        },
        loanAmount() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0) {
                result = this.financialInformation.propertyPrice - this.financialInformation.propertyDownPayment
            }
            return parseFloat(result.toFixed(2))
        },
        propertyTaxes() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.propertyTaxes !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.propertyTaxes.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        federalTaxes() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.stateTaxes !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.stateTaxes.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        propertyInsurance() {
            let result = 0
            if (this.financialInformation.propertyPrice !== 0 && this.financialInformation.propertyInsurance !== null) {
                result = (this.financialInformation.propertyPrice * parseFloat(this.financialInformation.propertyInsurance.text) / 12) / 100
            }
            return parseFloat(result.toFixed(2))
        },
        monthlyPayment() {
            let interest_rate = 0;
            let result = 0
            let years = 1;
            if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
                years = this.selection.selectedYears.value * 12
            }
            if (this.selection.selectedUser) {
                interest_rate = (typeof this.selection.selectedUser.interes_rate) === 'number' ? this.selection.selectedUser.interes_rate : parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
                const interestRateRatio = (interest_rate / 100) / 12

                const calcTop = interestRateRatio * this.loanAmount
                const calcBottom = 1 - (1 / Math.pow(1 + interestRateRatio, years))

                const interest = this.loanAmount * interestRateRatio
                const capital = calcTop / calcBottom - interest
                result = capital + interest
            }
            return parseFloat(result.toFixed(2))
        },
        totalMonthly() {
            let result = 0
            if (this.financialInformation.association !== null) {
                result = this.monthlyPayment + this.propertyTaxes + this.federalTaxes + this.propertyInsurance + this.pmi + this.financialInformation.association + this.financialInformation.otherMontlhyPayment
            }
            return parseFloat(result.toFixed(2))
        },
        capitalGain() {
            let result = 0
            if (this.financialInformation.propertyPrice !== null && this.financialInformation.propertyEstimateCapitalGainPlusvalia !== null) {
                result = (this.financialInformation.propertyPrice * this.financialInformation.propertyEstimateCapitalGainPlusvalia) / 12
            }
            return result
        },
        totalCapitalGain() {
            let result = 0
            if (this.financialInformation.propertyEstimateMonthlyIncome !== null) {
                result = this.financialInformation.propertyEstimateMonthlyIncome + this.capitalGain
            }
            return result
        },
        profit() {
            return this.totalCapitalGain - this.totalMonthly
        },
        totalIncome() {
            let totalIncome = 0
            const FAC = 0.06
            if (this.financialInformation.propertyPrice !== null) {
                totalIncome = ((this.profit * 12) * 5)
            }
            return totalIncome
        },
        netProfitPercent() {
            return (this.totalIncome / this.totalInversment) * 100
        },
        totalInversment() {
            let result = 0
            if (this.financialInformation.propertyDownPayment !== null && this.financialInformation.propertyBrokerPayment !== null && this.financialInformation.propertyClosingCostBuy.text !== undefined) {
                result = this.financialInformation.propertyDownPayment + this.financialInformation.propertyBrokerPayment + parseFloat(this.financialInformation.propertyClosingCostBuy.text)
            }
            return result
        }
    },
    methods: {
        init: function () {
            this.getSession();
            this.loadSectionUsers();
            this.loadTables();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
            this.getTableTemplate();

            let _context = this;
            setTimeout(() => {

                // Property Price Change
                let el: any = document.getElementById("propertyPrice");
                el.addEventListener("change", () => {
                    console.log(_context.financialInformation.propertyPrice)
                    _context.calculateProperty('estimateloan');
                    _context.calculateField("loanamount")
                })


                let propertyDownPayment: any = document.getElementById("propertyDownPayment");
                propertyDownPayment.addEventListener("change", () => {
                    _context.calculateProperty('estimateloan');
                    _context.calculateField("loanamount")
                })

                // let propertyEstimateLoan: any = document.getElementById("propertyEstimateLoan");
                // propertyEstimateLoan.addEventListener("change",() => {
                //     _context.calculateProperty('estimateloan');
                // })
            }, 3000)
        },
        submitFormula: async function (property: string, event: any = null) {
            if (event) event.target.disabled = true;

            let error = false;

            const params = {
                scenario: this.scenarioType.scenario_type_id,
                property: property,
                values: {
                    propertyEstimateLoan: this.financialInformation.propertyEstimateLoan,
                    propertyPrice: this.financialInformation.propertyPrice,
                    propertyDownPayment: this.financialInformation.propertyDownPayment,
                    propertyInteresRate: this.selection.selectedUser ? this.selection.selectedUser.interes_rate.toString().replace("%", "") : null,
                    propertySelectedYears: this.selection.selectedYears ? this.selection.selectedYears.value : null,
                    propertyPMI: this.pmi,
                    propertyClosingCostBuy: this.financialInformation.propertyClosingCostBuy?.text,
                    propertyBrokerPayment: this.financialInformation.propertyBrokerPayment,
                    propertyAnnualPropertyTaxes: this.financialInformation.propertyAnnualPropertyTaxes,
                    propertyMonthlyPropertyTaxes: this.financialInformation.propertyMonthlyPropertyTaxes,
                    propertyAnnualPropertyInsurance: this.financialInformation.propertyAnnualPropertyInsurance,
                    propertyMonthlyPropertyInsurance: this.financialInformation.propertyMonthlyPropertyInsurance,
                    propertyAnnualStateTaxes: this.financialInformation.propertyAnnualStateTaxes,
                    propertyMonthlyStateTaxes: this.financialInformation.propertyMonthlyStateTaxes,
                    propertyAnnualFederalTaxes: this.financialInformation.propertyAnnualFederalTaxes,
                    propertyMonthlyFederalTaxes: this.financialInformation.propertyMonthlyFederalTaxes,
                    propertyAssociationPayment: this.financialInformation.propertyAssociationPayment,
                    propertyPaymentMonthlyServices: this.financialInformation.propertyPaymentMonthlyServices,
                    propertyOtherMonthlyPayment: this.financialInformation.propertyOtherMonthlyPayment,
                    propertyMonthlyPercentageOccupancy: this.financialInformation.propertyMonthlyPercentageOccupancy,
                    propertyRentDay: this.financialInformation.propertyRentDay,
                    propertyEstimateMonthlyIncome: this.financialInformation.propertyEstimateMonthlyIncome,
                    propertyEstimateCapitalGainPlusvalia: this.financialInformation.propertyEstimateCapitalGainPlusvalia,
                }
            }

            this.financialInformation.propertyAverageAnnualNetProfit =
                params.values.propertyEstimateLoan +
                params.values.propertyPrice +
                params.values.propertyDownPayment +
                params.values.propertyInteresRate +
                params.values.propertySelectedYears +
                params.values.propertyPMI +
                params.values.propertyClosingCostBuy +
                params.values.propertyBrokerPayment +
                params.values.propertyAnnualPropertyTaxes +
                params.values.propertyMonthlyPropertyTaxes +
                params.values.propertyAnnualPropertyInsurance +
                params.values.propertyMonthlyPropertyInsurance +
                params.values.propertyAnnualStateTaxes +
                params.values.propertyMonthlyStateTaxes +
                params.values.propertyAnnualFederalTaxes +
                params.values.propertyMonthlyFederalTaxes +
                params.values.propertyAssociationPayment +
                params.values.propertyPaymentMonthlyServices +
                params.values.propertyOtherMonthlyPayment +
                params.values.propertyMonthlyPercentageOccupancy +
                params.values.propertyRentDay +
                params.values.propertyEstimateMonthlyIncome +
                params.values.propertyEstimateCapitalGainPlusvalia

        },
        changeRealtor: function ($event) {
            console.log($event);
            this.loadTables();
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                console.log(this.scenario.scenarioData);
            }
        },
        calculateField: function (field) {
            if (field == "loanamount") {
                if (this.financialInformation.propertyDownPayment) {
                    this.financialInformation.propertyEstimateLoan = (this.financialInformation.propertyPrice ? this.financialInformation.propertyPrice : 0) - (this.financialInformation.propertyDownPayment ? this.financialInformation.propertyDownPayment : 0);
                    this.financialInformation.propertyFloodInsurance.value = (((parseFloat(this.financialInformation.propertyFloodInsurance.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);

                    if (this.selection.selectedUser && this.financialInformation.propertyPrice && this.financialInformation.propertyDownPayment && !this.foreign) {
                        let avgPmi = this.selection.selectedUser.average_mip_pmi;

                        avgPmi = parseFloat(avgPmi.replace('%', ''))
                        avgPmi = avgPmi / 100;

                        let percentage = this.financialInformation.propertyDownPayment / this.financialInformation.propertyPrice;
                        if (percentage <= (this.selection.selectedUser.minimum_down_payment || this.selection.selectedUser.minimum_down_payment != "-" ? (this.selection.selectedUser.minimum_down_payment / 100) : 0)) {
                            let pmi = (avgPmi * this.financialInformation.propertyEstimateLoan) / 12;
                            this.pmi = Math.round(pmi);
                            console.log(this.pmi)

                        } else {
                            this.pmi = 0;
                        }
                    }
                }
            }
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        commaFormat(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        addUserExtraTables: async function () {
            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "mlo_foreign", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {
                    if (u.tabletype.includes("mlo")) {
                        u.fha = null;
                        u.isManuallyAdded = true;
                        u.interes_rate = null;
                        u.minimum_down_paymentlower = null;
                        u.minimum_down_paymenthigher = null;
                        u.average_mip_pmi = null;
                        u.backend_ratio = null;
                        u.maximum_loan = null;
                        u.minimum_down_payment = null;

                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && !ut.tabletype.includes("titlecompany"))

                        u.tables.forEach(usertable => {

                            let tabletype = usertable.tabletype;

                            let type = {
                                id: btoa(tabletype),
                                name: tabletype.split("_")[1][0].toUpperCase() + tabletype.split("_")[1].substring(1, tabletype.split("_")[1].length)
                            }

                            if (!this.listTablePropertiesMlo.find(a => a.id == type.id)) {
                                this.listTablePropertiesMlo.push(type);
                            }
                        })

                    }

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("mlo")) this.listMlos.push(user);
                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });

                this.listTablePropertiesMlo = this.listTablePropertiesMlo.filter(a => ["investment"].includes(a.name.toLowerCase()))
                console.log(this.listTablePropertiesMlo);
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        addMLOManually: function () {
            let tabletype = "mlo_primary"
            let w = {
                ...this.userMLO,
            }

            this.listMlos = this.listMlos.filter(a => !a.isManuallyAdded);

            let listUsers = [];

            let tablesMLO = this.tableTypesMLO.filter(a => a.key != "mlo_primary").map(a => { return { tabletype: a.key, tabledata: a.tables, id_tabletype: btoa(a.key) } })

            listUsers.push(w);
            listUsers = listUsers.map((u, z) => {

                u.fha = null;
                u.isManuallyAdded = true;
                u.interes_rate = null;
                u.minimum_down_paymentlower = null;
                u.minimum_down_paymenthigher = null;
                u.average_mip_pmi = null;
                u.backend_ratio = null;
                u.maximum_loan = null;
                u.z = z;
                u.minimum_down_payment = null;

                u.tables = [
                    {
                        id_tabletype: btoa(tabletype),
                        tabletype: tabletype,
                        tabledata: this.tables
                    },
                    ...tablesMLO
                ]

                let data: any[] = this.tables.find(a => a.id == '11')?.data;
                data = data.map((w: any) => { return { table_text: w.table_text ? w.table_text : "EMPTY", table_housing_expenses: w.table_housing_expenses?.length == 0 || isNaN(parseFloat(w.table_housing_expenses)) ? 0 : parseFloat(w.table_housing_expenses) } });


                let type = {
                    id: btoa(tabletype),
                    name: tabletype.split("_")[1][0].toUpperCase() + tabletype.split("_")[1].substring(1, tabletype.split("_")[1].length)
                }

                if (!this.listTableProperties.find(a => a.id == type.id)) {
                    this.listTableProperties.push(type);
                }

                return toRaw(u)
            })

            this.listMlos.push(listUsers[0])

            this.selection = {
                selectedUser: null,
                selectedProperty: null,
                selectedScoreCredit: null,
                selectedProgram: null,
                selectedYears: null
            }

            console.log(this.listMlos);

            Swal.fire({
                title: "Message",
                text: "Saved",
                icon: "success",
                timer: 3000,
                timerProgressBar: false
            });
        },
        changeTableType: function (type) {
            this.tableTypesMLO.forEach(a => {
                a.visible = false

                if (a.key == type) {
                    this.titleTable = a.name;
                    a.visible = true;
                }
            });

        },
        getTableTemplate: async function () {
            this.loaderConfig.isLoading = true;

            let types = this.tableTypesMLO.map(a => a.key).toString();

            const params = { type: types }
            const response = (await UserTableService.getTemplate(params)).data;

            if (!response.error) {
                let tabledata = response.data;
                let tableprimary = tabledata.find(a => a.tabletype == "mlo_primary");
                let tables = UserTableService.convertTable(JSON.parse(tableprimary.tabledata));
                let datatable = tabledata.filter(a => a.tabletype != "mlo_primary").map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });
                this.tables = tables;

                this.tableTypesMLO = this.tableTypesMLO.map((a: any) => {
                    let tables = datatable.find(b => b.tabletype == a.key);
                    if (tables) {
                        a.tables = tables.tabledata;
                    }
                    return { ...a };
                })

                console.log(this.tableTypesMLO);
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            // if (this.foreign) name = this.foreign;

            const params = { name: this.foreign ? "foreign_" + this.foreign : name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        loadTables: async function () {
            this.loaderConfig.isLoading = true
            let tabletype = `${this.userSession.role.replaceAll(" ", "").toLowerCase()}_table`

            let usertables: any[] = [];

            let q = {
                user: this.userSession.id_user ? this.userSession.id_user : this.userSession.iduser,
                tabletype: tabletype
            }

            const response = (await UserTableService.getTables(q)).data
            if (!response.error) {
                let responsedata = response.data.length > 0 ? response.data[0] : null;

                let tableData = response.data[0].tabledata;
                let table = JSON.parse(tableData);
                table = table.find((c: any) => c.id === '4');
                // console.log(table);

                if (!table)
                    return;

                let data = table.data.map((c: any) => c[0]);

                let propertyTaxes = data.find((c: any) => {
                    return c.values.find((v: any) => v.value === 'PROPERTY TAXES')
                })

                let stateTaxes = data.find((c: any) => {
                    return c.values.find((v: any) => v.value === 'STATE TAXES')
                })

                let propertyInsurance = data.find((c: any) => {
                    return c.values.find((v: any) => v.value === 'PROPERTY INSURANCE')
                })
                let propertyFloodInsurance = data.find((c: any) => {
                    return c.values.find((v: any) => v.value === 'PROPERTY FLOOD INSURANCE')
                })
                // console.log(data, propertyTaxes)

                if (propertyTaxes)
                    this.financialInformation.propertyTaxes = this.getObject(propertyTaxes.values)

                if (stateTaxes)
                    this.financialInformation.stateTaxes = this.getObject(stateTaxes.values)

                if (propertyInsurance)
                    this.financialInformation.propertyInsurance = this.getObject(propertyInsurance.values)

                if (propertyFloodInsurance)
                    this.financialInformation.propertyFloodInsurance = this.getObject(propertyFloodInsurance.values)

                this.calculateField("taxes");


                if (responsedata) {
                    let tabledata = JSON.parse(responsedata.tabledata);
                    usertables = UserTableService.convertTable(tabledata);

                    this.listPropertyTypes = usertables.find(a => a.id == '1').data.map((a: any, e: number) => { return { id: e, name: a.table_name, text: parseFloat(a.table_text) / 100 } });

                    const capitalaverages = usertables.find(a => a.id == '1').data;
                    const auctions = usertables.find(a => a.id == '2').data;
                    const comissions = usertables.find(a => a.id == '5').data;
                    const taxesandinsurance = usertables.find(a => a.id == '4');

                    this.listTableProperties = capitalaverages.map((j: any, w: number) => { return { key: w, name: j.table_name } });

                    taxesandinsurance.recalculate = () => {
                        taxesandinsurance.data.forEach((b: any) => {
                            // if(b.table_name == "PROPERTY TAXES") {
                            //     this.financialInformation.propertyTax = ((parseFloat(b.table_text) * this.propertyPrice) / 12).toFixed(2);
                            // }

                            // if(b.table_name == "PROPERTY INSURANCE") {
                            //     this.financialInformation.propertyInsurance = ((parseFloat(b.table_text) * this.propertyPrice) / 12).toFixed(2);
                            // }

                            // if(b.table_name == "PROPERTY FLOOD INSURANCE") {
                            //     this.financialInformation.propertyFloodInsurance = ((parseFloat(b.table_text) * this.propertyPrice) / 12).toFixed(2);
                            // }
                        })
                    }

                    taxesandinsurance.recalculate();

                    comissions.forEach((b: any) => {
                        if (b.table_name == "REAL ESTATE BROKER FEE") {
                            this.financialInformation.propertyBrokerPayment = b.table_text;
                        }
                    })

                    this.userTables = usertables;
                    console.log(taxesandinsurance);

                }
            }
            this.loaderConfig.isLoading = false;
        },
        calculateProperty: function (type: string) {

            if (type == "monthlytaxes") {
                this.financialInformation.propertyMonthlyPropertyTaxes = ((parseFloat(this.financialInformation.propertyAnnualPropertyTaxes ? this.financialInformation.propertyAnnualPropertyTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "monthlystatetaxes") {
                this.financialInformation.propertyMonthlyStateTaxes = ((parseFloat(this.financialInformation.propertyAnnualStateTaxes ? this.financialInformation.propertyAnnualStateTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "monthlyfederaltaxes") {
                this.financialInformation.propertyMonthlyFederalTaxes = ((parseFloat(this.financialInformation.propertyAnnualFederalTaxes ? this.financialInformation.propertyAnnualFederalTaxes : 0) / 12)).toFixed(0)
            }

            if (type == "estimated") {
                this.financialInformation.propertyMonthlyPropertyInsurance = (((this.financialInformation.propertyAnnualPropertyInsurance ? this.financialInformation.propertyAnnualPropertyInsurance : 0) / 12)).toFixed(0)
            }

            if (type == "estimateloan") {
                if (this.financialInformation.propertyPrice && this.financialInformation.propertyDownPayment)
                    this.financialInformation.propertyEstimateLoan = parseFloat(this.financialInformation.propertyPrice ? this.financialInformation.propertyPrice : 0) - parseFloat(this.financialInformation.propertyDownPayment ? this.financialInformation.propertyDownPayment : 0)
            }

            if (type == "taxesandinsurance") {
                const taxesandinsurance = this.userTables.find((a: any) => a.id == '4');
                taxesandinsurance.recalculate();
            }

            if (type == "closingcostbuy" || type == "estimateloan") {
                if (this.selectedTitleCompany && this.financialInformation.propertyPrice) {
                    this.financialInformation.propertyClosingCostBuy.calculate();
                }
            }

            if (type == "monthlypercentageoccupancy") {
                if (this.financialInformation.propertyMonthlyPercentageOccupancy) {
                    this.financialInformation.propertyMonthlyPercentageOccupancyValue = (this.financialInformation.propertyMonthlyPercentageOccupancy / 100) * (365 / 12);
                }
                else {
                    this.financialInformation.propertyMonthlyPercentageOccupancyValue = null;
                }
            }

            if (type == "estimatemonthlyincome" || type == "monthlypercentageoccupancy") {
                if (this.financialInformation.propertyRentDay && this.financialInformation.propertyMonthlyPercentageOccupancyValue) {
                    this.financialInformation.propertyEstimateMonthlyIncome = this.financialInformation.propertyRentDay * this.financialInformation.propertyMonthlyPercentageOccupancyValue;
                }
                else {
                    this.financialInformation.propertyEstimateMonthlyIncome = null;
                }
            }

        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.listMlos = toRaw(response.data.mlo_users);

                this.listTitleCompanies = toRaw(response.data.titlecompany_users);
                this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                this.listInspectors = toRaw(response.data.inspector_users);
                this.listInspectors = this.listInspectors.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                if (this.listMlos.length > 0) {
                    this.listMlos = this.listMlos.map((u) => {

                        u.fha = null;
                        u.interes_rate = null;
                        u.minimum_down_paymentlower = null;
                        u.minimum_down_paymenthigher = null;
                        u.average_mip_pmi = null;
                        u.backend_ratio = null;
                        u.maximum_loan = null;
                        u.isManuallyAdded = false;

                        if (u.tables) {
                            u.tables = u.tables.map((t: any) => {
                                let tables = JSON.parse(t.tabledata);

                                let formatTables: any[] = [];

                                if (tables.constructor === Array) {
                                    tables.map((c: any) => {
                                        if (c.convert) {
                                            let listdata: any = []

                                            c.data.forEach((v: any) => {
                                                let j: any = {}

                                                v.map((e: any) => {
                                                    let o: any = {}
                                                    e.values.forEach((w: any) => {
                                                        o[`${e.name}_${w.name}`] = w.value
                                                    })

                                                    return o
                                                }).forEach((p: any) => {
                                                    for (let f in p) {
                                                        j[f] = p[f]
                                                    }
                                                })

                                                v = [j]
                                                listdata.push(j)
                                            })

                                            c.data = listdata
                                        }

                                        formatTables.push(c);
                                    })
                                }





                                let tabletype = {
                                    id: btoa(t.tabletype),
                                    name: t.tabletype.split("_")[1][0].toUpperCase() + t.tabletype.split("_")[1].substring(1, t.tabletype.split("_")[1].length)
                                }

                                if (!this.listTablePropertiesMlo.find(a => a.id == tabletype.id)) {
                                    this.listTablePropertiesMlo.push(tabletype);
                                }

                                return { ...t, id_tabletype: tabletype.id, tabledata: tables };
                            })
                        }

                        return toRaw(u)
                    })
                }

                this.addUserExtraTables();
                console.log(this.listUserSections)
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        filterTable: function () {

            let f: any = {
                property: this.listTablePropertiesMlo.find(a => a.id == this.filters.property),
                score: this.filters.score,
                program: this.listPrograms.find(a => a.id == this.filters.program),
                year: this.listYears.find(a => a.id == this.filters.year)
            }

            let q = 0

            for (let a in f) {
                if (f[a] != undefined) {
                    q++;
                }
            }

            if (q == 4) {
                this.filters.active = true;
                this.filters.program_name = f.program.name;
                this.listMlos.forEach((user: any) => {

                    user.fha = null;
                    user.interes_rate = "-";
                    user.minimum_down_paymentlower = "-";
                    user.minimum_down_paymenthigher = "-";
                    user.average_mip_pmi = "-";
                    user.backend_ratio = "-";
                    user.maximum_loan = "-";
                    user.minimum_down_payment = "-";

                    if (user.tables) {
                        let t: any = user.tables.find((b: any) => b.id_tabletype == f.property.id)

                        let g = t.tabledata.find(k => k.id == 12);
                        if (g) {
                            g.data.forEach(e => {
                                if (e.table_name == f.program.name) {
                                    user.minimum_down_payment = e.table_value;
                                }
                            })
                        }

                        if (t) {
                            let y: any = t.tabledata.find((o: any) => o.id == (f.year.id + 1).toString())
                            if (y) {
                                const w = (f.program.name.toLowerCase() == "conventional" ? "coventional" : f.program.name.toLowerCase())

                                y.data.forEach((e: any) => {
                                    if (e[w + "_credit"]) {
                                        let min = parseFloat(e[w + "_credit"].split("-")[0].trim());
                                        let max = parseFloat(e[w + "_credit"].split("-")[1].trim());
                                        let s = parseFloat(f.score)

                                        if (s >= min && s <= max) {
                                            user.interes_rate = e[w + "_percent"] + "%"

                                            t.tabledata.forEach((j: any) => {
                                                let k = j.data.find((a: any) => a?.table_name?.toLowerCase() == w || a?.table_name?.toLowerCase().includes(f.program.name.toLowerCase()))
                                                if (k) {
                                                    if (k?.table_minimmum_down_payment) user.minimum_down_paymentlower = k?.table_minimmum_down_payment + "%";
                                                    if (k?.table_score_credit_higher_than_b) user.minimum_down_paymenthigher = k?.table_score_credit_higher_than_b + "%";
                                                    // if (k?.table_mip || k?.table_pmi) user.average_mip_pmi = (k?.table_pmi ? k?.table_pmi : k?.table_mip) + "%";
                                                    if (k?.table_backend_ratio) user.backend_ratio = k?.table_backend_ratio + "%";
                                                    if (k?.table_input_mla) user.maximum_loan = "$" + k?.table_input_mla;

                                                    if (k?.table_mip || k?.table_pmi) {
                                                        // if (this.filters.program_name == "FHA" || this.filters.program_name == "VA") user.average_mip_pmi = k?.table_mip + "%";
                                                        // else user.average_mip_pmi = k?.table_pmi + "%";
                                                        user.average_mip_pmi = k?.table_pmi + "%";
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })

                            }
                        }
                    }
                })
            }

            if (this.foreign && f.year) {
                this.filters.active = true;
                this.listMlos.forEach((user: any) => {

                    user.interes_rate = "-";
                    user.minimum_down_payment = "-";

                    // console.log(user);

                    if (user.tables) {
                        let t = user.tables.find(a => a.tabletype == "mlo_foreign");
                        if (t) {
                            let w = t.tabledata.find(b => b.id == '2');
                            if (w) {
                                w.data.forEach(g => {
                                    if (g.table_name == "YEARS" && g.table_text == f.year.value) {
                                        user.interes_rate = w.data.find(d => d.table_name == "INTEREST RATE")?.table_text;
                                        user.minimum_down_payment = w.data.find(d => d.table_name == "DOWN PAYMENT")?.table_text;
                                        return;
                                    }

                                    if (g.table_name2 == "YEARS" && g.table_text2 == f.year.value) {
                                        user.interes_rate = w.data.find(d => d.table_name == "INTEREST RATE")?.table_text2;
                                        user.minimum_down_payment = w.data.find(d => d.table_name == "DOWN PAYMENT")?.table_text2;
                                        return;
                                    }
                                })
                            }
                        }
                    }
                })
            }

        },
        loadData: function (user: any) {
            this.selection.selectedUser = user;
            this.financialInformation.propertyPmiMip = this.selection.selectedUser.average_mip_pmi >= 20 ? 0 : ((parseFloat(this.selection.selectedUser.average_mip_pmi) * this.financialInformation.mortgage) / 12).toFixed(2);
            this.selection.selectedProperty = this.listTablePropertiesMlo.find(c => c.id == this.filters.property);
            this.selection.selectedProgram = this.listPrograms.find(c => c.id == this.filters.program);
            this.selection.selectedYears = this.listYears.find(c => c.id == this.filters.year);

            console.log(toRaw(this.listMlos));

            Swal.fire({
                title: "Message",
                text: this.selection.selectedUser.username + " " + this.selection.selectedUser.lastname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })
            // console.log(user, this.selection);
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;

            const table = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata;
            const closingconsts = table.find((e: any) => e.id == "1").data
            closingconsts.forEach((a: any) => {
                if (a.table_name3 == "BUYER") {
                    this.financialInformation.propertyClosingCostBuy = { text: null, value: a.table_closing_close ? a.table_closing_close / 100 : 0 };
                    this.financialInformation.propertyClosingCostBuy.calculate = () => {
                        this.financialInformation.propertyClosingCostBuy.text = (this.financialInformation.propertyPrice * this.financialInformation.propertyClosingCostBuy.value).toFixed(0);
                    }

                    if (this.financialInformation.propertyPrice) this.financialInformation.propertyClosingCostBuy.calculate();
                }
            })

            this.calculateProperty("closingcostbuy");

            Swal.fire({
                title: "Title Company",
                text: this.selectedTitleCompany.companyname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })
        },
        getObject: function (values: any[]) {
            let object: any = {};
            values.forEach((el: any) => {
                object[el.name] = el.value;
            });

            return object;
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.target.disabled = true;

            let px = this.$route.name;

            if (this.foreign) {
                px = "foreign-" + this.foreign.replaceAll("_", "-");
            }

            this.financialInformation.propertyPmiMip = this.pmi;

            let params: any = {
                code: px.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    mortgage: { ...this.selection },
                    titlecompany: { ...this.selectedTitleCompany },
                    financial_information: { ...this.financialInformation },
                    filters: { ...this.filters },
                },
                id_user: this.userSession.iduser
            }

            if (this.foreign) {
                params.data.scenario_type = this.foreign;
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.target.disabled = false;
        }
    }
})
