
import { defineComponent } from "vue";
import Swal from "sweetalert2";
import UserService from "@/shared/services/user.service";
import LookUsers from "./LookUsers.vue";
import RealtorsNotifications from "./RealtorsNotifications.vue";

export default defineComponent({
  props: {
    helpBar: {
      type: Boolean,
      default: false,
    },
    helpDescription: {
      type: String,
      default: "",
    },
    helpVideo: {
      type: String,
      default: "",
    },
    support: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LookUsers,
    RealtorsNotifications,
  },
  data() {
    return {
      userSession: null,
      supportMessage: {
        iduser: null,
        message: null,
      },
      loaderConfig: {
        isLoading: true,
        fullPage: true,
      },
    };
  },
  created() {
    this.getSession();
  },
  methods: {
    openModal(target) {
      this.$emit("openModal", target);
    },
    getSession: function () {
      let session =
        localStorage.getItem("usersession") &&
        localStorage.getItem("usersession").length > 0
          ? JSON.parse(localStorage.getItem("usersession"))
          : null;

      if (session) {
        this.userSession = session;
      } else {
        UserService.logout("Session expired!");
      }
    },
    submitReport: async function (event) {
      this.loaderConfig.isLoading = true;
      event.target.disabled = true;
      let params: any = {
        ...this.supportMessage,
        iduser: this.userSession.iduser,
      };

      const response = (await UserService.registerSupportMessage(params)).data;

      if (!response.error) {
        this.supportMessage.iduser = null;
        this.supportMessage.message = null;
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });

        let w: any = window;
        w.modalclose("#exampleModalSupport");
      } else {
        this.$toast.error("Error: " + response.message, {
          position: "top-right",
        });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
      event.target.disabled = false;
    },
  },
});
