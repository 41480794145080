import{ createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ToastPlugin from 'vue-toast-notification';
import VueDatePicker from '@vuepic/vue-datepicker';

// Custom Components
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import BModal from "./components/BModal.vue";
import TopBar from "./components/admin/TopBar.vue";

import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App)

app.component("datepicker", VueDatePicker);
app.component("Header", Header)
app.component("Footer", Footer)
app.component("BModal", BModal)
app.component("TopBar", TopBar)
app.use(store)
app.use(router)
app.use(ToastPlugin)
app.mount('#app')
