export const environment = {

    // LOCALHOST
    // FORMULA_API: "http://realtoolsapi.test/api/",
    // URL_API: "http://realtors.test/api/",
    // URL_UPLOADS: "http://realtors.test/api/",
    
    // DEVENV
    // FORMULA_API: "https://formulas.realtortools.org/api/",
    // URL_API: "https://testapi.realtortools.org/api/",
    // URL_UPLOADS: "https://testapi.realtortools.org/",
    
    // PRODUCTION
    FORMULA_API: "https://formulas.realtortools.org/api/",
    URL_API: "https://api.realtortools.org/api/",
    URL_UPLOADS: "https://api.realtortools.org/",

    PAYPAL_CDN_PROD: 'https://www.paypal.com/sdk/js?client-id=AaRy5a-jrjyJyw1uwShcSlRq1icMJsXcxc4YOYpYrvQVMWtYgIeu5uxFIaaSWAUkpnfci916RVhHHxNU&vault=true&intent=subscription',
    PAYPAL_CDN_DEV: 'https://www.paypal.com/sdk/js?client-id=AUPFEmrCj8PpFKBs_uypyc0uF_kLKFBUmnEnTMor1puhDJlOMYc6iRKuhkRfkcZBmCpZcvmCPQxQ0oaJ&vault=true&intent=subscription',
    PAYPAL_CDN_PLAN_DEV: 'P-4VM50995BR5845303MVTORSY'
}
