

import useVuelidate from '@vuelidate/core';
import { defineComponent, toRaw } from 'vue';
import { useToast } from "vue-toast-notification";
import UserService from '@/shared/services/user.service';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';


export default defineComponent({
    data() {
        return {
            changePasswordColor: false,
            disablePassword: true,
            userRole: null,
            usersession: null,
            v$: useVuelidate(),
            $toast: useToast(),
            selectedImage: null,
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            userForm: {
                idrol: null,
                username: null,
                lastname: null,
                phonenumber: null,
                email: null,
                state: null,
                country: null,
                address: null,
                zipcode: null,
                password: null,
                companyname: null,
                licensenumber: null
            }
        }
    },
    validations() {
        return {
            userForm: {
                username: { required },
                lastname: { required },
                phonenumber: { required },
                email: { required },
                state: { required },
                country: { required },
                address: { required },
                zipcode: { required },
                password: { required },
                companyname: { required },
                licensenumber: { required }
            }
        }
    },
    created() {
        this.init();
    },
    components: {
        Loading
    },
    methods: {
        init: function () {
            this.getSession()
            if (this.usersession) {
                this.getUserInfo()
            }
        },
        toggleDisablePassword() {
            this.disablePassword = !this.disablePassword;
            this.changePasswordColor = true;
            console.log(this.disablePassword)
        },
        getUserInfo: async function () {
            this.loaderConfig.isLoading = true
            let response = (await UserService.getUser({ iduser: toRaw(this.usersession).id_user ? toRaw(this.usersession).id_user : toRaw(this.usersession).iduser })).data

            if (!response.error) {
                this.userForm = { ...response.data, password: atob(response.data.password) }
                this.usersession = { ...response.data, password: atob(response.data.password) }
                localStorage.setItem("usersession", JSON.stringify(this.usersession))
            }
            else {
                this.$toast.error("Error", { position: 'top-right' })
                console.log(response)
            }
            this.loaderConfig.isLoading = false
        },
        submit: async function () {
            this.loaderConfig.isLoading = true
            let response = (await UserService.update(this.userForm)).data

            if (!response.error) {
                this.$toast.success("Saved", { position: 'top-right' })
                // this.v$.userForm.password.$disabled = false
                this.getUserInfo()
                this.disablePassword = true
                this.changePasswordColor = false
            }
            else {
                this.$toast.error("Error", { position: 'top-right' })
                console.log(response)
            }
            this.loaderConfig.isLoading = false
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? toRaw(JSON.parse(localStorage.getItem("usersession"))) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.usersession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        handleImageUpload(event: any) {
            this.selectedImage = event.target.files[0];
        },
        async uploadImage() {
            const formData = new FormData();
            formData.append('picture', this.selectedImage);

            try {
                const response = await axios.post(`http://api.realtortools.org/api/user/${this.usersession.iduser}/picture`, formData);
                console.log('Imagen subida con éxito', response.data);
                location.reload();
            } catch (error) {
                console.error('Error al subir la imagen', error);
            }
        },
    }
})



