
import { defineComponent, toRaw } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import UserService from '@/shared/services/user.service';
import { useToast } from 'vue-toast-notification';


export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            $toast: useToast(),
            userSession: null,
            userRole: null,
            typeUser: 1,
            listRoles: [],
            inspectionTypes: [
                {
                    id: 0,
                    name: "ATTIC"
                },
                {
                    id: 1,
                    name: "ASBESTOS"
                },
                {
                    id: 2,
                    name: "ELECTRIC"
                },
                {
                    id: 3,
                    name: "RADON"
                },
                {
                    id: 4,
                    name: "ROOF INSPECTION"
                },
                {
                    id: 5,
                    name: "POINT INSPECTION"
                },
                {
                    id: 6,
                    name: "FOUNDATION INSPECTION"
                },
                {
                    id: 7,
                    name: "HVAC"
                },
                {
                    id: 8,
                    name: "MOLD INSPECTION"
                },
                {
                    id: 9,
                    name: "POOL INSPECTION"
                },
                {
                    id: 10,
                    name: "HEATING SYSTEM"
                },
                {
                    id: 11,
                    name: "LEAD"
                },
                {
                    id: 12,
                    name: "PLUMBINO"
                },
                {
                    id: 13,
                    name: "WDO INSPECTION"
                }
            ],
            countryList: [],
            stateList: [],
            submitButton: true,
            userForm: {
                idrol: null,
                username: null,
                lastname: null,
                phonenumber: null,
                email: null,
                state: null,
                country: null,
                address: null,
                zipcode: null,
                password: null,
                confirmPassword: null,
                companyname: null,
                licensenumber: null,
                inspectiontype: null
            },
            userInfo: null,
            countyList: [],
            // paypal: null

        }
    },
    mounted() {
        // Renderizar el botón de PayPal después de que el componente se haya montado

    },
    validations() {
        return {
            userForm: {
                username: { required },
                lastname: { required },
                phonenumber: { required },
                email: { required },
                state: { required },
                country: { required },
                address: { required },
                zipcode: { required },
                companyname: { required },
                password: { required },
                licensenumber: { required },
                inspectiontype: { required }
            }
        }
    },
    created: function () {
        this.init()
        this.getSections();
        // this.renderPayPalButton();

    },
    methods: {
        vo: function() {
            return false;
        },
        init: async function () {
            // await this.loadRoles()
            // await this.loadCountries()

            if (this.$route.query.type && this.$route.query) {
                this.typeUser = parseInt(this.$route.query.type.toString())
            }
            else {
                this.$router.push({
                    path: "/"
                })
            }
            this.loadUserInfo();


            // this.getSession();
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.userSession = session

                if (!this.userSession.hasPayments && !this.userSession.active) {
                    // this.$router.push({
                    //     path: "/register/payment",
                    //     query: {
                    //         type: this.userSession.idrol,
                    //         userId: this.userSession.iduser
                    //     }
                    // })

                    this.$toast.success("Your information has been saved, make the payment to continue!", { position: 'top-right', duration: 10000 })
                    let _context = this;
                    setTimeout(() => {
                        window.location.href = `/register/payment?type${_context.userSession.idrol}&userId=${_context.userSession.iduser}`;
                    }, 1000);
                }
            }
        },
        // renderPayPalButton() {
        //     // Asegúrate de que paypal esté definido en este ámbito
        //     let script = document.createElement('script');
        //     script.src = 'https://www.paypal.com/sdk/js?client-id=AaRy5a-jrjyJyw1uwShcSlRq1icMJsXcxc4YOYpYrvQVMWtYgIeu5uxFIaaSWAUkpnfci916RVhHHxNU&vault=true&intent=subscription';
        //     script.setAttribute('data-sdk-integration-source', 'button-factory');
        //     document.body.appendChild(script);

        //     let code = '';
        //     if(this.typeUser == 1) {
        //         code = 'P-4V730448PL5551157MVSLW3Q';
        //     } else if(this.typeUser == 2) {
        //         code = 'P-4V730448PL5551157MVSLW3Q';
        //     } else if(this.typeUser == 3) {
        //         code = 'P-4V730448PL5551157MVSLW3Q';
        //     }

        //     let _context = this;

        //     setTimeout(() => {
        //         _context.paypal = (window as any).paypal;

        //         if (_context.paypal) {
        //             _context.paypal.Buttons({
        //                 style: {
        //                     shape: 'rect',
        //                     color: 'gold',
        //                     layout: 'vertical',
        //                     label: 'subscribe',
        //                 },
        //                 createSubscription: (data, actions) => {
        //                     console.log("data => ", data);
        //                     return actions.subscription.create({
        //                         plan_id: code,
        //                     });
        //                 },
        //                 onApprove: (data, actions) => {
        //                     alert(data.subscriptionID);
        //                 },
        //             }).render('#paypal-btn-container');
        //         } else {
        //             // Puedes manejar el caso en el que paypal no está definido
        //             console.error('PayPal no está definido. Asegúrate de que el SDK de PayPal se haya cargado correctamente.');
        //         }
        //     }, 500)
        // },
        loadUserInfo() {
            let userInfo = localStorage.getItem('usersession')
            this.userInfo = JSON.parse(userInfo)
        },

        submit: async function (form: any) {
            this.submitButton = false
            form = JSON.parse(JSON.stringify(form))
            form.idrol = this.typeUser
            const response = await UserService.register(form)

            if (!response.data.error) {
                localStorage.clear()
                localStorage.setItem("usersession", JSON.stringify(response.data.data));

                this.$router.push({
                    path: "/register/payment",
                    query: {
                        type: this.typeUser,
                        userId: response.data.data.iduser
                    }
                })

                this.$toast.success("Your information has been saved, make the payment to continue!", { position: 'top-right', duration: 10000 })
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.submitButton = true
        },
        loadRoles: async function () {
            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list
                this.listRoles.forEach((role, index) => {
                    if (role.name == "Admin") {
                        this.listRoles.splice(index, 1)
                    }
                })
            }
            else {
                console.log(resdata.message)
            }
        },
        getSections: async function () {
            const response = await UserService.getZoneStates()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                list.forEach((s: any) => {
                    this.stateList.push(s.name);
                })
            }
            else {
                console.log(resdata.message)
            }
        },
        async loadCounty() {
            const response = await UserService.getCounty(this.userForm.state)
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.countyList = list
            }
            else {
                console.log(resdata.message)
            }
        }
    }
})

