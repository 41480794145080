
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import Loading from 'vue-loading-overlay';
import { useToast } from "vue-toast-notification";
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'

//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    props: {
        foreign: null
    },
    data() {
        return {
            v$: useVuelidate(),
            totalMonthlyPayment: 0,
            userSession: null,
            userSection: null,
            scenarioType: null,
            scenario: {
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            configDatePicker: {
                date: null
            },
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            filters: {
                property: null,
                score: null,
                program: null,
                year: null,
                program_name: "FHA",
                active: false
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            listUserSections: [],
            listTableProperties: [],
            listScoreCredits: [
                {
                    key: 0,
                    name: "300 TO 579"
                },
                {
                    key: 1,
                    name: "580 TO 669"
                },
                {
                    key: 2,
                    name: "670 TO 739"
                },
                {
                    key: 3,
                    name: "740 TO 799"
                },
                {
                    key: 4,
                    name: "800 TO 850"
                }
            ],
            listPrograms: [
                {
                    id: 0,
                    name: "FHA"
                },
                {
                    id: 1,
                    name: "CONVENTIONAL"
                },
                {
                    id: 2,
                    name: "VA"
                }
            ],
            listYears: [
                {
                    id: 0,
                    name: "15 YEARS",
                    value: 15
                },
                {
                    id: 1,
                    name: "30 YEARS",
                    value: 30
                }
            ],
            videoTutorial1: null,
            selection: {
                selectedUser: null,
                selectedProperty: null,
                selectedScoreCredit: null,
                selectedProgram: null,
                selectedYears: null
            },
            financialInformation: {
                propertyPrice: null,
                propertyTaxes: null,
                stateTaxes: null,
                propertyInsurance: null,
                association: null,
                totalMonthly: null,
                propertyFloodInsurance: null,
                otherMontlhyPayment: null,
                propertyClosingCostBuy: null,
                totalMonthlyPayment: null
            },
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            selectedTitleCompany: null,
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    computed: {
        scenarioValid: function () {
            let k = true;
            if (this.v$.customer.$invalid) k = false;

            return k;
        }
    },
    created() {
        this.init();
    },
    methods: {
        init: function () {
            this.getSession();
            this.loadSectionUsers();
            this.loadTable();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
            let _context = this;
            setTimeout(() => {

                // Property Price Change
                let el: any = document.getElementById("propertyPriceTotalCash");
                el.addEventListener("input", () => {
                    console.log(_context.financialInformation.propertyPrice)
                    _context.calculateField('taxes');
                })


            }, 3000)
        },
        submitFormula: async function (property: string, event: any = null) {
            if (event) event.target.disabled = true;

            let error = false;

            const params = {
                scenario: this.scenarioType.scenario_type_id,
                property: property,
                values: {
                    propertyPrice: this.financialInformation.propertyPrice,
                    propertyTaxes: this.financialInformation.propertyTaxes?.value,
                    propertyInsurance: this.financialInformation.propertyInsurance?.value,
                    propertyFloodInsurance: this.financialInformation.propertyFloodInsurance?.value,
                    propertyStateTaxes: this.financialInformation.stateTaxes?.value,
                    propertyAssociation: this.financialInformation.association,
                    propertyOtherMonthlyPayment: this.financialInformation.otherMontlhyPayment
                }
            }


            this.totalMonthlyPayment = 
                params.values.propertyPrice +
                params.values.propertyTaxes +
                params.values.propertyInsurance +
                params.values.propertyFloodInsurance +
                params.values.propertyStateTaxes +
                params.values.propertyAssociation +
                params.values.propertyOtherMonthlyPayment;
    
        },
        changeRealtor: function ($event) {
            console.log($event);
            this.loadTable();
        },
        commaFormat(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                // console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        calculateField: function (field) {
            if (field == "taxes") {
                if (this.financialInformation.propertyPrice) {
                    this.financialInformation.propertyTaxes.value = (((parseFloat(this.financialInformation.propertyTaxes.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.stateTaxes.value = (((parseFloat(this.financialInformation.stateTaxes.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.propertyInsurance.value = (((parseFloat(this.financialInformation.propertyInsurance.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                    this.financialInformation.propertyFloodInsurance.value = (((parseFloat(this.financialInformation.propertyFloodInsurance.text) * this.financialInformation.propertyPrice) / 12) / 100).toFixed(0);
                }
                // else {
                //     this.financialInformation.propertyTaxes.value = this.financialInformation.propertyTaxes.text;
                //     this.financialInformation.stateTaxes.value = this.financialInformation.stateTaxes.text;
                //     this.financialInformation.propertyInsurance.value = this.financialInformation.propertyInsurance.text;
                //     this.financialInformation.propertyFloodInsurance.value = this.financialInformation.propertyFloodInsurance.text;
                // }
            }
        },
        addUserExtraTables: async function () {
            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {
                    if (u.tabletype.includes("mlo")) {
                        u.fha = null;
                        u.isManuallyAdded = true;
                        u.interes_rate = null;
                        u.minimum_down_paymentlower = null;
                        u.minimum_down_paymenthigher = null;
                        u.average_mip_pmi = null;
                        u.backend_ratio = null;
                        u.maximum_loan = null;

                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && !ut.tabletype.includes("titlecompany"))

                        u.tables.forEach(usertable => {

                            let tabletype = usertable.tabletype;

                            let type = {
                                id: btoa(tabletype),
                                name: tabletype.split("_")[1][0].toUpperCase() + tabletype.split("_")[1].substring(1, tabletype.split("_")[1].length)
                            }

                            if (!this.listTableProperties.find(a => a.id == type.id)) {
                                this.listTableProperties.push(type);
                            }
                        })

                    }

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("mlo")) this.listUserSections.push(user);
                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });

                this.listTableProperties = this.listTableProperties.filter(a => !["investment", "foreign"].includes(a.name.toLowerCase()))
                // console.log(this.listTableProperties);
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                // console.log(this.scenario.scenarioData);
            }
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            // console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            // if (this.foreign) name = this.foreign;

            const params = { name: this.foreign ? "foreign_" + this.foreign:name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                // console.log(response.data.data);
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        calculateTotal() {
            this.totalMonthlyPayment = parseInt(this.financialInformation.propertyPrice) + parseInt(this.financialInformation.propertyTaxes?.text) + parseInt(this.financialInformation.propertyInsurance?.text) +
                parseInt(this.financialInformation.propertyFloodInsurance?.text) + parseInt(this.financialInformation.stateTaxes?.text) + parseInt(this.financialInformation.association) +
                parseInt(this.financialInformation.otherMontlhyPayment);
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.userSection = toRaw(response.data);
                if (this.userSection.mlo > 0) {
                    this.listUserSections = this.userSection.mlo_users
                    this.listTitleCompanies = toRaw(this.userSection.titlecompany_users);
                    this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    this.listInspectors = toRaw(this.userSection.inspector_users);
                    this.listInspectors = this.listInspectors.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    if (this.listUserSections.length > 0) {
                        this.listUserSections = this.listUserSections.map((u) => {

                            u.fha = null;
                            u.interes_rate = null;
                            u.minimum_down_paymentlower = null;
                            u.minimum_down_paymenthigher = null;
                            u.average_mip_pmi = null;
                            u.backend_ratio = null;
                            u.maximum_loan = null;

                            if (u.tables) {
                                u.tables = u.tables.map((t: any) => {
                                    let tables = JSON.parse(t.tabledata);

                                    let formatTables: any[] = [];

                                    if (tables.constructor === Array) {
                                        tables.map((c: any) => {

                                            if (c.convert) {
                                                let listdata: any = []

                                                c.data.forEach((v: any) => {
                                                    let j: any = {}

                                                    v.map((e: any) => {
                                                        let o: any = {}
                                                        e.values.forEach((w: any) => {
                                                            o[`${e.name}_${w.name}`] = w.value
                                                        })

                                                        return o
                                                    }).forEach((p: any) => {
                                                        for (let f in p) {
                                                            j[f] = p[f]
                                                        }
                                                    })

                                                    v = [j]
                                                    listdata.push(j)
                                                })

                                                c.data = listdata
                                            }

                                            formatTables.push(c);
                                        })
                                    }



                                    let tabletype = {
                                        id: btoa(t.tabletype),
                                        name: t.tabletype.split("_")[1][0].toUpperCase() + t.tabletype.split("_")[1].substring(1, t.tabletype.split("_")[1].length)
                                    }

                                    if (!this.listTableProperties.find(a => a.id == tabletype.id)) {
                                        this.listTableProperties.push(tabletype);
                                    }

                                    return { ...t, id_tabletype: tabletype.id, tabledata: tables };
                                })
                            }

                            return toRaw(u)
                        })

                    }
                }

                this.addUserExtraTables();
                // console.log(this.listUserSections)
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        filterTable: function () {

            let f: any = {
                property: this.listTableProperties.find(a => a.id == this.filters.property),
                score: this.filters.score,
                program: this.listPrograms.find(a => a.id == this.filters.program),
                year: this.listYears.find(a => a.id == this.filters.year)
            }

            let q = 0

            for (let a in f) {
                if (f[a] != undefined) {
                    q++;
                }
            }

            if (q == 4) {
                this.filters.active = true;
                this.filters.program_name = f.program.name;
                this.listUserSections.forEach((user: any) => {

                    user.fha = null;
                    user.interes_rate = "-";
                    user.minimum_down_paymentlower = "-";
                    user.minimum_down_paymenthigher = "-";
                    user.average_mip_pmi = "-";
                    user.backend_ratio = "-";
                    user.maximum_loan = "-";

                    if (user.tables) {
                        let t: any = user.tables.find((b: any) => b.id_tabletype == f.property.id)
                        if (t) {
                            let y: any = t.tabledata.find((o: any) => o.id == (f.year.id + 1).toString())
                            if (y) {
                                const w = (f.program.name.toLowerCase() == "conventional" ? "coventional" : f.program.name.toLowerCase())

                                y.data.forEach((e: any) => {
                                    if (e[w + "_credit"]) {
                                        let min = parseFloat(e[w + "_credit"].split("-")[0].trim());
                                        let max = parseFloat(e[w + "_credit"].split("-")[1].trim());
                                        let s = parseFloat(f.score)

                                        if (s >= min && s <= max) {
                                            user.interes_rate = e[w + "_percent"] + "%"

                                            t.tabledata.forEach((j: any) => {
                                                let k = j.data.find((a: any) => a?.table_name?.toLowerCase() == w || a?.table_name?.toLowerCase().includes(f.program.name.toLowerCase()))
                                                if (k) {
                                                    if (k?.table_minimmum_down_payment) user.minimum_down_paymentlower = k?.table_minimmum_down_payment + "%";
                                                    if (k?.table_score_credit_higher_than_b) user.minimum_down_paymenthigher = k?.table_score_credit_higher_than_b + "%";
                                                    if (k?.table_mip || k?.table_pmi) user.average_mip_pmi = (k?.table_pmi ? k?.table_pmi : k?.table_mip) + "%";
                                                    if (k?.table_backend_ratio) user.backend_ratio = k?.table_backend_ratio + "%";
                                                    if (k?.table_input_mla) user.maximum_loan = "$" + k?.table_input_mla;
                                                }
                                            })
                                        }
                                    }
                                })

                            }
                        }
                    }
                })
            }
        },
        loadData: function (user: any) {
            // console.log(user);
            this.selection.selectedUser = user;
            this.selection.selectedProperty = this.listTableProperties.find(c => c.id == this.filters.property);
            this.selection.selectedProgram = this.listPrograms.find(c => c.id == this.filters.program);
            this.selection.selectedYears = this.listYears.find(c => c.id == this.filters.year);

            // console.log(user, this.selection);
        },
        loadTable: async function () {
            let model = {
                user: this.userSession.iduser,
                tabletype: `${this.userSession.role.toLowerCase()}_table`
            }
            const response = await UserTableService.getTables(model);

            let tableData = response.data.data[0].tabledata;
            let table = JSON.parse(tableData);
            table = table.find((c: any) => c.id === '4');

            if (!table)
                return;

            let data = table.data.map((c: any) => c[0]);

            let propertyTaxes = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY TAXES')
            })

            let stateTaxes = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'STATE TAXES')
            })

            let propertyInsurance = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY INSURANCE')
            })
            let propertyFloodInsurance = data.find((c: any) => {
                return c.values.find((v: any) => v.value === 'PROPERTY FLOOD INSURANCE')
            })
            // console.log(data, propertyTaxes)

            if (propertyTaxes)
                this.financialInformation.propertyTaxes = this.getObject(propertyTaxes.values)

            if (stateTaxes)
                this.financialInformation.stateTaxes = this.getObject(stateTaxes.values)

            if (propertyInsurance)
                this.financialInformation.propertyInsurance = this.getObject(propertyInsurance.values)

            if (propertyFloodInsurance)
                this.financialInformation.propertyFloodInsurance = this.getObject(propertyFloodInsurance.values)

            this.calculateField("taxes");
        },
        getObject: function (values: any[]) {
            let object: any = {};
            values.forEach((el: any) => {
                object[el.name] = el.value;
            });

            return object;
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
            this.financialInformation.propertyClosingCostBuy = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata.find((e: any) => e.id == "1").data.find((j: any) => j.table_name == "CLOSING CLOSE").table_closing_close;
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.disabled = true;

            this.financialInformation.totalMonthlyPayment = this.totalMonthlyPayment;
            let px = this.$route.name;

            if (this.foreign) {
                px = "foreign-" + this.foreign.replaceAll("_", "-");
            }

            let params: any = {
                code: px.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    financial_information: { ...this.financialInformation },
                    selection_data: { ...this.selection },
                },
                id_user: this.userSession.iduser
            }

            if (this.foreign) {
                params.data.scenario_type = this.foreign;
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})
