
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import { defineComponent, toRaw } from 'vue';
import Loading from 'vue-loading-overlay';
import { useToast } from "vue-toast-notification";
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import TotalCashMonthlyPaymentView from './TotalCashMonthlyPaymentView.vue';
import TotalFinancialMonthlyPaymentView from './TotalFinancialMonthlyPaymentView.vue';
import PasiveCashView from './PasiveCashView.vue';
import PasiveFinancialView from './PasiveFinancialView.vue';
import ShortTermCashView from './ShortTermCashView.vue';
import ShortTermFinancialView from './ShortTermFinancialView.vue';
import FlipAsIsView from './FlipAsIsView.vue';
import FlipPropertyReadyView from './FlipPropertyReadyView.vue';
import Vue3Html2pdf from 'vue3-html2pdf';

export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            userSession: null,
            userSection: null,
            scenarioType: null,
            videoTutorial1: null,
            scenario: {
                user: null,
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            foreign: {
                selectType: null,
                selectTypeName: null
            },
            filters: {
                property: null,
                score: null,
                program: null,
                year: null,
                program_name: "mlo_foreign",
                active: false
            },
            selection: {
                selectedUser: null,
                selectedProperty: null,
                selectedScoreCredit: null,
                selectedProgram: null,
                selectedYears: null
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            listUserSections: [],
            listTableProperties: [],
            listTablePropertiesMlo: [],
            listPrograms: [
                {
                    id: 0,
                    name: "FHA"
                },
                {
                    id: 1,
                    name: "CONVENTIONAL"
                },
                {
                    id: 2,
                    name: "VA"
                }
            ],
            listYears: [
                {
                    id: 0,
                    name: "15 YEARS",
                    value: 15
                },
                {
                    id: 1,
                    name: "30 YEARS",
                    value: 30
                }
            ],
            selectedTitleCompany: null,
            financialInformation: {
                mortgage: 15000,
                //
                propertyMonthlyBudgetPayment: null,
                propertyDownPayment: null,
                propertyMortgage: null,
                propertyInterestRate: null,
                propertyPmiMip: null,
                //
                propertyLoanYears: null,
                propertyFederalTaxes: null,
                propertyPmiMip2: null,
                propertyPmiMip3: null,
                //
                propertyName: null,
                propertyMonthlyPayment: null,
                propertyMonthlyPercent: null,
                propertyAverageAssociation: null,
                //
                propertyEstimatePriceHourse: null
            },
            listPropertyTypes: [],
            listTitleCompanies: [],
            listInspectors: [],
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
        }
    },
    components: {
        Loading,
        ResidentialView,
        TotalCashMonthlyPaymentView,
        TotalFinancialMonthlyPaymentView,
        PasiveCashView,
        PasiveFinancialView,
        ShortTermCashView,
        ShortTermFinancialView,
        FlipAsIsView,
        FlipPropertyReadyView,
        Vue3Html2pdf,
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    created() {
        this.init();
    },
    computed: {
        scenarioValid: function () {
            let k = true;
            if (this.v$.customer.$invalid) k = false;

            return k;
        },

        monthlyPayment() {
            let interest_rate = 0;
            let result = 0
            let years = 1;
            if (this.selection.selectedYears !== undefined && this.selection.selectedYears !== null) {
                years = this.selection.selectedYears.value * 12
            }
            if (this.selection.selectedUser) {
                interest_rate = parseFloat(this.selection.selectedUser.interes_rate.replace('%', ''))
                const interestRateRatio = (interest_rate / 100) / 12

                const calcTop = interestRateRatio * this.scenario.scenarioData.financial_information.propertyEstimateLoan
                const calcBottom = 1 - (1 / Math.pow(1 + interestRateRatio, years))

                const interest = this.scenario.scenarioData.financial_information.propertyEstimateLoan * interestRateRatio
                const capital = calcTop / calcBottom - interest
                result = capital + interest
            }
            return parseFloat(result.toFixed(2))
        },
        totalMonthly() {
            let result = 0
            if (this.scenario.scenarioData.financial_information.association !== null) {
                result = this.monthlyPayment + this.scenario.scenarioData.financial_information.propertyTaxes + this.scenario.scenarioData.financial_information.federalTaxes + this.scenario.scenarioData.financial_information.propertyInsurance + + this.scenario.scenarioData.financial_information.association + this.scenario.scenarioData.financial_information.otherMontlhyPayment
            }
            return parseFloat(result.toFixed(2))
        }
    },
    methods: {
        init: function () {
            this.getSession();
            this.loadSectionUsers();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
        },
        addUserExtraTables: async function () {
            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });



                this.listTableProperties = this.listTableProperties.filter(a => !["investment", "foreign"].includes(a.name.toLowerCase()))
                console.log(this.listTitleCompanies);
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                console.log(this.scenarioMail);
            })
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                console.log(this.scenario.scenarioData);
            }
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        selectInvestmentType: function (type: any = null) {
            this.foreign.selectTypeName = (this.foreign.selectType == 0 ? "residential" : "investment") + "-" + type.target.value;
        },
        selectForeignType: function (type: any = null) {
            if (this.foreign.selectType == 0) {
                this.foreign.selectTypeName = "residential-" + type;
            }

            if (this.foreign.selectType == 1) {
                let typename = this.foreign.selectTypeName.split("-");
                if (typename.length == 3) {
                    typename.pop();
                    this.foreign.selectTypeName = typename.toString().replaceAll(",", "-");
                }

                this.foreign.selectTypeName += "-" + type;
            }
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            const params = { name: name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.userSection = toRaw(response.data);
                if (this.userSection.mlo > 0) {
                    this.listUserSections = this.userSection.mlo_users

                    this.listTitleCompanies = toRaw(this.userSection.titlecompany_users);
                    this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    this.listInspectors = toRaw(this.userSection.inspector_users);
                    this.listInspectors = this.listInspectors.map((w) => {
                        if (w.tables) {
                            w.tables = w.tables.map((a: any) => {
                                a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                                return a;
                            })
                        }

                        return toRaw(w);
                    })

                    this.addUserExtraTables();

                    if (this.listUserSections.length > 0) {
                        this.listUserSections = this.listUserSections.map((u) => {

                            u.years = null;
                            u.residential_investment = null;
                            u.down_payment = null;
                            u.interes_rate = null;

                            if (u.tables) {
                                u.tables = u.tables.map((t: any) => {
                                    let tables = JSON.parse(t.tabledata);

                                    let formatTables: any[] = [];

                                    if (tables.constructor === Array) {
                                        tables.map((c: any) => {

                                            if (c.convert) {
                                                let listdata: any = []

                                                c.data.forEach((v: any, i: number) => {
                                                    let j: any = {}

                                                    v.map((e: any) => {
                                                        let o: any = {}
                                                        e.values.forEach((w: any) => {
                                                            o[`${e.name}_${w.name}`] = w.value
                                                        })

                                                        return o
                                                    }).forEach((p: any) => {
                                                        for (let f in p) {
                                                            j[f] = p[f]
                                                        }
                                                    })

                                                    v = [j]
                                                    listdata.push(j)
                                                })

                                                c.data = listdata
                                            }

                                            if (t.tabletype == "mlo_foreign") {
                                                let tabletype = {
                                                    id: btoa(c.name),
                                                    name: c.id == '1' ? "RESIDENTIAL" : "INVESTMENT"
                                                }

                                                if (!this.listTableProperties.find(a => a.id == tabletype.id)) {
                                                    this.listTableProperties.push(tabletype);
                                                }

                                                c.table = tabletype.name
                                                c.years = parseInt(c.id) - 1
                                            }

                                            formatTables.push(c);
                                        })
                                    }



                                    let tabletype = {
                                        id: btoa(t.tabletype),
                                        name: t.tabletype.split("_")[1][0].toUpperCase() + t.tabletype.split("_")[1].substring(1, t.tabletype.split("_")[1].length)
                                    }

                                    if (!this.listTablePropertiesMlo.find(a => a.id == tabletype.id)) {
                                        this.listTablePropertiesMlo.push(tabletype);
                                    }


                                    return { ...t, tabledata: formatTables };
                                })
                            }

                            return toRaw(u)
                        })

                        console.log(toRaw(this.listUserSections));
                    }
                }
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        filterTable: function () {

            let f: any = {
                property: this.listTablePropertiesMlo.find(a => a.id == this.filters.property),
                year: this.listYears.find(a => a.id == this.filters.year)
            }

            let q = 0

            for (let a in f) {
                if (f[a] != undefined) {
                    q++;
                }
            }

            if (q == Object.keys(f).length) {
                this.filters.active = true;
                this.listUserSections.forEach((user: any) => {

                    user.years = f.year.name;
                    user.residential_investment = f.property.name;
                    user.down_payment = "-";
                    user.interes_rate = "-";

                    if (user.tables) {
                        let t: any = user.tables.find((b: any) => b.tabletype == this.filters.program_name);
                        if (t) {
                            let o = t.tabledata.find((j: any) => j.table == f.property.name);
                            if (o) {
                                let w = o.data
                                if (w.length > 0) {
                                    w.forEach((c: any) => {
                                        if (c.table_name == f.year.name.split(" ")[1]) {
                                            Object.keys(toRaw(c)).forEach((j: any) => {
                                                if (c[j] == f.year.value) {
                                                    user.down_payment = w[1][j]
                                                    user.interes_rate = w[2][j] + "%"
                                                }
                                            })
                                        }

                                    })
                                }
                            }
                        }

                    }
                })
            }
        },
        getObject: function (values: any[]) {
            let object: any = {};
            values.forEach((el: any) => {
                object[el.name] = el.value;
            });

            return object;
        },
        loadData: function (user: any) {
            this.selection.selectedUser = user;
            this.financialInformation.propertyPmiMip = this.selection.selectedUser.average_mip_pmi >= 20 ? 0 : ((parseFloat(this.selection.selectedUser.average_mip_pmi) * this.financialInformation.mortgage) / 12).toFixed(2);
            this.selection.selectedProperty = this.listTablePropertiesMlo.find(c => c.id == this.filters.property);
            this.selection.selectedProgram = this.listPrograms.find(c => c.id == this.filters.program);
            this.selection.selectedYears = this.listYears.find(c => c.id == this.filters.year);

            // console.log(user, this.selection);
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.disabled = true;

            let params: any = {
                code: this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    mortgage: { ...this.selection },
                    financial_information: { ...this.financialInformation },
                },
                id_user: this.userSession.iduser
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})
