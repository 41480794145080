

import { defineComponent, toRaw } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UserService from '@/shared/services/user.service';
import router from '@/router';


export default defineComponent({
    data() {
        return {
            listRoles: [],
            productSelected: "PRODUCTS",
            videoLink: null,
            isCollapsed: true,
            isCollapsed1: true,
            isCollapsed2: true,
            isCollapsed3: true,
            isCollapsed4: true,
            isCollapsed5: true,
            isCollapsed6: true,
            isCollapsed7: true,
            isCollapsed8: true,
            isCollapsed9: true,
            isCollapsed10: true,
            isCollapsed11: true,
            isCollapsed12: true,
            isCollapsed13: true,
            isCollapsed14: true,
            isCollapsed15: true,
            isCollapsed16: true,
            isCollapsed17: true,
            isCollapsed18: true,
            isCollapsed19: true
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    created() {
        this.init()
    },
    methods: {
        init: function () {
            this.loadRoles()
        },
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        toggleCollapse1() {
            this.isCollapsed1 = !this.isCollapsed1;
        },
        toggleCollapse2() {
            this.isCollapsed2 = !this.isCollapsed2;
        },
        toggleCollapse3() {
            this.isCollapsed3 = !this.isCollapsed3;
        },
        toggleCollapse4() {
            this.isCollapsed4 = !this.isCollapsed4;
        },
        toggleCollapse5() {
            this.isCollapsed5 = !this.isCollapsed5;
        },
        toggleCollapse6() {
            this.isCollapsed6 = !this.isCollapsed6;
        },
        toggleCollapse7() {
            this.isCollapsed7 = !this.isCollapsed7;
        },
        toggleCollapse8() {
            this.isCollapsed8 = !this.isCollapsed8;
        },
        toggleCollapse9() {
            this.isCollapsed9 = !this.isCollapsed9;
        },
        toggleCollapse10() {
            this.isCollapsed10 = !this.isCollapsed10;
        },
        toggleCollapse11() {
            this.isCollapsed11 = !this.isCollapsed11;
        },
        toggleCollapse12() {
            this.isCollapsed12 = !this.isCollapsed12;
        },
        toggleCollapse13() {
            this.isCollapsed13 = !this.isCollapsed13;
        },
        toggleCollapse14() {
            this.isCollapsed14 = !this.isCollapsed14;
        },
        toggleCollapse15() {
            this.isCollapsed15 = !this.isCollapsed15;
        },
        toggleCollapse16() {
            this.isCollapsed16 = !this.isCollapsed16;
        },
        toggleCollapse17() {
            this.isCollapsed17 = !this.isCollapsed17;
        },
        toggleCollapse18() {
            this.isCollapsed18 = !this.isCollapsed18;
        },
        toggleCollapse19() {
            this.isCollapsed19 = !this.isCollapsed19;
        },
        loadRoles: async function () {
            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list
                this.listRoles.forEach((role, index) => {
                    role.abb = role.name.toLowerCase().replaceAll(" ", "")

                    if (role.name == "MLO") {
                        role.name = "MORTGAGE LOAN ORIGINATOR"
                    }

                    role.name = role.name.toUpperCase()
                })
            }
            else {
                console.log(resdata.message)
            }
        },
        openModal: function (modalname: string, option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.productSelected = option;


            const modal = new bootstrap.Modal('#' + modalname)
            modal.show();

            const modalopen = document.getElementById(modalname)
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.productSelected = null;
            })
        }
    }
})


