

import { defineComponent, toRaw } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UserService from '@/shared/services/user.service';
import router from '@/router';
import vueRecaptcha from 'vue3-recaptcha2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default defineComponent({
    data() {
        return {
            listRoles: [],
            productSelected: "PRODUCTS",
            videoLink: null,
            v$: useVuelidate(),
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            formContact: {
                email: null,
                phone: null,
                firstname: null,
                lastname: null,
                message: null,
                role: null
            },
            recaptchaResponse: null
        }
    },
    validations() {
        return {
            formContact: {
                email: { email, required },
                phone: { required },
                firstname: { required },
                lastname: { required },
                message: { required }
            }
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        vueRecaptcha,
        Loading
    },
    created() {
        this.init()
    },
    methods: {
        init: function () {
            this.loadRoles()
            console.log()
        },
        loadRoles: async function () {
            this.loaderConfig.isLoading = true

            const response = await UserService.getRoles()
            let resdata = response.data

            if (!resdata.error) {
                let list: any = toRaw(resdata.data)
                this.listRoles = list

                this.formContact.role = this.listRoles.find(a => a.name.replaceAll(" ", "").toLowerCase() == this.$route.params?.role.toString().replaceAll("-", "").toLowerCase())

                if (!this.formContact.role) {
                    router.push({
                        path: "/"
                    })
                }
            }
            else {
                console.log(resdata.message)
            }

            this.loaderConfig.isLoading = false
        },
        submitContactForm: async function () {
            this.loaderConfig.isLoading = true

            const params = {
                ...this.formContact,
                id_rol: this.formContact.role?.idRole
            }

            let response = (await UserService.sendContactForm(params)).data

            if (!response.error) {
                this.$toast.success(response.message, { position: 'top-right' })

                this.formContact = {
                    email: null,
                    phone: null,
                    firstname: null,
                    lastname: null,
                    message: null,
                    role: this.formContact.role
                }

                this.recaptchaExpired();

                console.log(this.formContact)

                router.push({ path: "/" });
            }
            else {
                this.$toast.error("Error", { position: 'top-right' })
                console.log(response)
            }

            this.loaderConfig.isLoading = false
        },
        recaptchaVerified(res: any) {
            this.recaptchaResponse = res;
        },
        recaptchaExpired() {
            let recaptcha: any = this.$refs.vueRecaptcha;
            recaptcha.reset();

            this.recaptchaResponse = null;
        },
        openModal: function (modalname: string, option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.productSelected = option;


            const modal = new bootstrap.Modal('#' + modalname)
            modal.show();

            const modalopen = document.getElementById(modalname)
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.productSelected = null;
            })
        }
    }
})


