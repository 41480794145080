
import { defineComponent } from "vue";

export default defineComponent({
  setup(props, context) {
    const openModal = (target) => {
      alert(target)
      context.emit("openModal", target);
    };
    return {
      openModal,
    };
  },
  data() {
    return {
      view: "",
    };
  },
  components: {},

  mounted() {},
  created() {
    this.init();
  },

  methods: {
    init() {
      this.initMethods();
      console.log(this.$route);
      var pathname = location.pathname;
      if (pathname.indexOf("admin") > -1) {
        this.view = "admin";
      } else {
        this.view = "portal";
      }
    },
    initMethods: function () {
      let w: any = window;
      w.modalclose = function (modal: any) {
        var openmodal = w.bootstrap.Modal.getInstance(modal);

        openmodal.hide();
      };
      w.closeAllModals = function () {
        const btnclose: any = document
          .querySelector(".modal")
          .querySelector('[aria-label="Close"]');
        btnclose.click();
      };
      w.openModal = function (modal: any) {
        var openmodal = new w.bootstrap.Modal(document.getElementById(modal), {
          keyboard: false,
        });

        openmodal.show();
      };
    },
  },
});
