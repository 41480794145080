
import { defineComponent } from 'vue'
import UserService from '@/shared/services/user.service';

export default defineComponent({
    data() {
        return {
            userSession: null,
            inspectorServices: [],
            servicesOptions: [
                'ATTIC',
                'ELECTRIC',
                'FOUNDATION INSPECTOR',
                'FULL INSPECTION',
                'HEATING SYSTEM',
                'HVAC',
                'LEAD',
                'MOLD INSPECTION',
                'PLUMBING',
                'POINT INSPECTION',
                'RADON',
                'ROOF INSPECTION',
                'WDO INSPECTION',
            ],
            serviceSelected: '',
            success: false,
            error: false,
            message: ''
        }
    },
    async created() {
        await this.getSession();
        await this.getServices();
    },
    methods: {
        async getSession() {
            let session = await localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        async getServices() {
            let params = {
                inspectorID: this.userSession?.iduser
            }

            let response = (await UserService.getInspectorServices(params)).data;

            if (response.error) {
                this.error = true
                this.message = response.message
            } else {
                this.inspectorServices = JSON.parse(response.data)
            }
        },
        async addService() {
            if (!this.inspectorServices.includes(this.serviceSelected) && this.serviceSelected !== undefined && this.serviceSelected !== null && this.serviceSelected !== '') {

                this.inspectorServices.push(this.serviceSelected)
                this.serviceSelected = ''

                let params = {
                    inspectorID: this.userSession?.iduser,
                    services: JSON.stringify(this.inspectorServices)
                }

                let response = (await UserService.saveInspectorServices(params)).data;

                if (response.error) {
                    this.error = true
                    this.message = response.message
                } else {
                    this.success = true
                    this.message = response.data

                    setInterval(() => {
                        this.success = false
                        this.message = ''
                    }, 2000)
                }
            }
        }
    }
})
