
import UserService from '@/shared/services/user.service';
import { defineComponent } from 'vue';



export default defineComponent({
    data() {
        return {
            userRole: null,
            refreshSession: null,
            usersession: null,
            currentRoute: this.$route.path.substring(this.$route.path.lastIndexOf("/") + 1, this.$route.path.length)
        }
    },
    created() {
        this.init();
    },
    methods: {
        init: function () {
            this.refreshSession = setInterval(() => {
                this.getSession()
            }, 250)
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userRole = session.role ? session.role : session.tipo_rol
                this.usersession = session
            } else {
                clearInterval(this.refreshSession)
                UserService.logout("Session expired!")
            }
        },

        cerrarSesion: function () {
            clearInterval(this.refreshSession)
            UserService.logout()
        },
    },
});


