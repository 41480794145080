
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      showing: false,
    };
  },
  props: {
    name: {
      type: String,
      default: `modal-${Math.random() * (99999 - 1) + 1}`,
    },
    buttonLabel: {
      type: String,
      default: "Open Modal",
    },
    videoID: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    altButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModal() {
      let _window: any = window;
      let bootstrap = _window.bootstrap;
      const modal = new bootstrap.Modal(`#${this.name}`);
      modal.show();

      // Check if exist a video on the modal
      if (this.videoID !== "") {
        const video: any = document.getElementById(this.videoID);
        video.play();
        const modalopen = document.getElementById(this.name);
        modalopen.addEventListener("hidden.bs.modal", (event) => {
          video.pause();
        });
      }
    },
  },
});
